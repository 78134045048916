:--theme {
  & .zb-table {
    border-spacing: 0;
    border-collapse: collapse;
    border-bottom: var(--zb-table-border, 0);
    font-size: var(--zb-table-font-size);

    & .zb-table-button {
      display: inline-block;
      position: relative;
      padding-right: 4px;
      margin-right: 16px;

      & > .zb-icon {
        margin: 0;
        position: absolute;
        top: 50%;
        transform: translate(100%, -50%);
        right: 0;
      }
    }

    & th {
      font-weight: var(--zb-table-column-header-font-weight);
      text-align: left;
    }

    & thead th,
    & tfoot th {
      height: var(--zb-table-column-header-height);
      padding: var(--zb-table-column-header-padding);
      background: var(--zb-table-column-header-background);
      border-top: var(--zb-table-row-border);
      border-bottom: var(--zb-table-row-border);

      &:first-child {
        border-left: var(--zb-table-row-border);
      }

      &:last-child {
        border-right: var(--zb-table-row-border);
      }
    }

    & thead tr.zb-table-columns th {
      color: var(--zb-table-column-header-color);
      font-size: var(--zb-table-column-header-font-size);
      line-height: var(--zb-table-column-header-line-height);
    }

    & td,
    & tbody th {
      padding: var(--zb-table-cell-padding);
      color: var(--zb-table-cell-color);
      line-height: var(--zb-table-cell-line-height);
      background: var(--zb-table-cell-background);
      border: var(--zb-table-row-border);
    }

    & tbody tr:hover {
      & td,
      & th {
        background: var(--zb-table-row-hover-cell-background);
      }
    }

    & .zb-pager {
      line-height: var(--zb-input-height);
    }
    & .zb-pager-form,
    & .zb-pager-text {
      &:last-child {
        float: right;
      }
    }

    & .zb-table-accordion-toggle {
      display: none;
      position: absolute;
      right: var(--zb-accordion-header-icon-margin-right);
      top: 50%;
      transform: translateY(-50%);
      padding: 6px;
    }

    & .zb-checkbox-input + label {
      font-weight: normal;
    }

    & caption {
      text-align: left;
      text-align: start;
      padding: 0 0 var(--zb-table-cell-padding);
    }
  }

  & .zb-checked-table {
    & td,
    & th {
      & .zb-checkbox:first-child {
        margin-right: var(--zb-table-cell-checkbox-margin-right);
      }
    }
  }

  & .zb-nested-table {
    & td {
      padding: var(--zb-nested-table-cell-padding);
    }

    & thead th {
      padding: var(--zb-nested-table-head-padding);
    }

    & thead th,
    & td {
      &:last-child {
        padding-right: var(--zb-nested-table-cell-padding-left);
      }

      &:first-child {
        padding-left: var(--zb-nested-table-cell-padding-left);
      }
    }
  }

  & .zb-table-toolbar {
    display: flex;
    line-height: var(--zb-input-height);
    justify-content: space-between;

    & .zb-table-button {
      margin-right: 28px;
    }
  }

  & .zb-accordion,
  & .zb-card {
    & .zb-table {
      width: 100%;
    }
  }

  & .zb-card-body {
    & .zb-flushed-table {
      --zb-card-table-margin: calc(0 - var(--zb-card-padding));
      width: calc(100% + calc(var(--zb-card-padding) * 2));
      margin-left: var(--zb-card-table-margin);
      margin-right: var(--zb-card-table-margin);

      &:first-child {
        margin-top: var(--zb-card-table-margin);
      }
      &:last-child {
        margin-bottom: var(--zb-card-table-margin);
      }
    }
  }

  & .zb-flushed-table {
    & td {
      padding: var(--zb-flushed-table-cell-padding);
    }

    & thead th {
      padding: var(--zb-flushed-table-head-padding);
    }

    & thead th,
    & td {
      &:last-child {
        padding-right: var(--zb-flushed-table-cell-padding-left);
      }

      &:first-child {
        padding-left: var(--zb-flushed-table-cell-padding-left);
      }
    }

    & thead,
    & thead th:first-child,
    & tfoot th:first-child,
    & tbody th:first-child,
    & tbody td:first-child,
    & td:first-child,
    & th:first-child {
      border-left: none;
    }

    & thead th:last-child,
    & tfoot th:last-child,
    & tbody th:last-child,
    & tbody td:last-child,
    & td:last-child,
    & th:last-child {
      border-right: none;
    }

    & thead tr:first-child {
      border-top: none;
      & th {
        border-top: none;
      }
    }

    & tbody:last-child tr:last-child td,
    & tfoot:last-child tr:last-child th {
      border-bottom: none;
    }
  }

  & .zb-complex-table {
    & tbody {
      & td,
      & th {
        border-left: none;
        border-right: none;
      }
    }

    & thead th,
    & tfoot th {
      padding: var(--zb-complex-table-column-header-padding);
      height: var(--zb-complex-table-column-header-height);
    }

    & td,
    & tbody th {
      padding: var(--zb-complex-table-cell-padding);
      height: var(--zb-complex-table-cell-height);
    }

    & th.zb-table-toolbar-container {
      height: var(--zb-complex-table-toolbar-height);
      padding: var(--zb-complex-table-toolbar-padding);
    }

    & td:first-child,
    & th:first-child {
      padding-left: var(--zb-table-first-cell-padding);
    }

    & td:last-child,
    & th:last-child {
      padding-right: var(--zb-table-first-cell-padding);
    }

    & .zb-select-input-wrapper {
      & .zb-icon {
        width: var(--zb-table-toolbar-select-icon-size);
        height: var(--zb-table-toolbar-select-icon-size);
      }
    }
  }

  & .zb-table-toolbar-actions {
    display: flex;
    justify-content: space-between;
    & .zb-table-toolbar-select {
      & .zb-lookup {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  & .zb-table-dropdown {
    display: inline-block;
  }
}

@media (--mobile-viewport) {
  :--theme {
    & .zb-responsive-table {
      & thead,
      & tbody,
      & th,
      & td,
      & tr {
        display: block;
        border: none;
      }

      & caption {
        padding: var(--zb-table-cell-padding);
      }

      & thead tr.zb-table-columns {
        display: none;
      }

      & tfoot,
      & thead {
        & td,
        & th {
          border: none;

          &:first-child,
          &:last-child {
            border: none;
          }
        }
        & tr:last-child {
          border-bottom: none;
        }
      }

      & tr {
        border-top: var(--zb-table-row-border);
        border-left: var(--zb-table-row-border);
        border-right: var(--zb-table-row-border);
        border-bottom: 0;
        &:last-child {
          border-bottom: var(--zb-table-row-border);
        }
      }

      & tbody {
        & td,
        & th {
          padding-top: 0;
          padding-bottom: 0;
          font-weight: bold;

          &[data-heading]:before {
            content: attr(data-heading) ": ";
            color: var(--zb-table-column-header-color);
            font-weight: normal;
          }
          &:first-child {
            padding-top: var(--zb-card-padding);
            padding-bottom: var(--zb-card-padding);

            &[data-heading]:before {
              content: "";
            }
          }
          &:last-child {
            padding-bottom: var(--zb-card-padding);
          }
        }

        & th {
          border: none;
        }
      }
      & .zb-table-accordion-toggle {
        display: inline-block;
        width: var(--zb-table-accordion-toggle-size);
        height: var(--zb-table-accordion-toggle-size);
      }

      &.zb-checked-table {
        & tbody {
          & td,
          & th {
            display: none;

            &:first-child {
              display: block;
              padding: var(--zb-table-card-header-padding);
              position: relative;

              &:before {
                content: "";
              }

              & + td,
              & + th {
                padding-top: var(--zb-card-padding);
              }
            }
          }

          & tr:last-child {
            & td:last-child {
              padding-bottom: var(--zb-card-padding);
            }
          }

          & tr:hover {
            & td,
            & th {
              background: inherit;

              &:first-child {
                background: var(--zb-table-row-hover-cell-background);
                color: var(--zb-table-cell-header-color);
              }
            }
          }
        }
        & .zb-table-accordion-open {
          & td,
          & th {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            &:first-child {
              border-bottom: var(--zb-table-row-border);
            }
          }
        }
      }

      & th.zb-table-toolbar-container,
      &.zb-complex-table th.zb-table-toolbar-container {
        padding: 0;
        & .zb-table-toolbar {
          & > * {
            padding: var(--zb-table-card-header-padding);
          }
        }
      }

      & .zb-table-toolbar {
        flex-direction: column;
        line-height: inherit;

        & > * {
          border-top: var(--zb-table-row-border);

          &:first-child {
            border-top: none;
          }
        }
      }
    }

    & .zb-card,
    & .zb-accordion {
      & .zb-responsive-table {
        margin-left: calc(0 - var(--zb-card-padding));
        margin-right: calc(0 - var(--zb-card-padding));
        width: calc(100% + calc(var(--zb-card-padding) * 2));

        & tr {
          border-top: var(--zb-table-row-border);
          border-left: 0;
          border-right: 0;

          &:last-child {
            border-bottom: 0;
          }
        }
        &:first-child {
          margin-top: calc(0 - var(--zb-card-padding));
        }
      }
    }

    & .zb-table {
      & .zb-pager-form {
        & .zb-input {
          min-width: 5em !important;
        }

        & .zb-button-secondary {
          padding-left: 11px;
          padding-right: 11px;
        }
      }
    }

    & .zb-button-with-icon-after.zb-table-button {
      text-indent: -1000px;
      padding: 0 16px;
      margin-right: 0;

      & .zb-icon {
        transform: translate(-50%, -50%);
      }
    }

    & .zb-table-toolbar {
      & .zb-lookup-input {
        min-height: var(--zb-table-toolbar-select-height);
        line-height: var(--zb-table-toolbar-select-height);
        font-size: var(--zb-table-toolbar-select-font-size);
      }
      & .zb-lookup-input-wrapper {
        min-height: var(--zb-table-toolbar-select-height);
      }

      & .zb-table-dropdown {
        border: none;
        min-height: auto;
        background: inherit;
        display: inline-block;
        min-height: auto;

        color: var(--zb-link-color);

        & .zb-select-input-wrapper {
          padding: 0 24px 0 0;

          & .zb-icon {
            padding: 0;
          }
        }
      }
    }

    & .zb-flushed-table {
      & thead,
      & thead th,
      & tfoot th,
      & tbody th,
      & tbody td,
      & td,
      & th {
        border-left: none;
        border-right: none;
      }
    }

    & .zb-nested-table {
      & thead,
      & thead th,
      & tfoot th,
      & tbody th,
      & tbody td,
      & td,
      & th {
        border-left: none;
        border-right: none;
      }

      & thead th:first-child,
      & tfoot th:first-child,
      & tbody th:first-child,
      & tbody td:first-child,
      & td:first-child,
      & th:first-child {
        border-left: var(--zb-table-row-border);
      }

      & thead th:last-child,
      & tfoot th:last-child,
      & tbody th:last-child,
      & tbody td:last-child,
      & td:last-child,
      & th:last-child {
        border-right: var(--zb-table-row-border);
      }
    }
  }
}
