@import '@zambezi/sdk/src/flyout/css/base.css';
@import '@zambezi/sdk/src/flyout/css/theme.css';

:--theme {
	& .zb-flyout {
		&.zb-flyout-has-close-button .zb-flyout-body {
			padding-right: calc(
				var(--zb-flyout-close-button-offset) +
					var(--zb-flyout-close-button-width) + 10px
			);
		}
	}
	& .zb-flyout-body {
		font-size: var(--zb-flyout-font-size);
		color: var(--zb-flyout-color, #000);
	}

	& .zb-flyout-close-button {
		float: right;
		margin-top: var(--zb-flyout-close-button-offset);
		margin-right: var(--zb-flyout-close-button-offset);

		& .zb-icon-core-flyout-close {
			color: var(--zb-flyout-close-button-color);
			width: 14px;
			height: 14px;
		}
	}
}
