@import '@zambezi/sdk/src/side-panel/css/base.css';
@import '@zambezi/sdk/src/side-panel/css/theme.css';

:--theme {
  & .zb-sidepanel {
    background: var(--zb-sidepanel-background);
    padding-left: var(--zb-sidepanel-padding-left);
    font-size: var(--zb-sidepanel-body-font-size);
    bottom: var(--zb-sidepanel-position-bottom);
    top: var(--zb-sidepanel-position-top);
    transform: var(--zb-sidepanel-transform);
    overflow: visible;

    & .zb-sidepanel-inner {
      box-shadow: 0 2px 2px 0 var(--zb-sidepanel-shadow-color);
    }
  }

  & .zb-sidepanel-close {
    & .zb-icon {
      display: block;
    }
  }

  & .zb-sidepanel-body {
    max-height: var(--zb-sidepanel-body-max-height);
  }

  & .zb-sidepanel-footer {
    border-top: var(--zb-sidepanel-separator);
    box-shadow: none;
  }

  & .zb-sidepanel-body-content {
    & p {
      margin-top: 0;
    }

    & ul {
      padding-left: var(--zb-sidepanel-list-padding-left);
      & li {
        margin-bottom: var(--zb-sidepanel-list-item-margin-bottom);
      }
    }
  }

  & .zb-sidepanel-header {
    &:not(:first-child) {
      margin-top: 0;
      box-shadow: none;
    }
  }

  & .zb-sidepanel-header-content {
    font-size: var(--zb-sidepanel-title-font-size);
    line-height: var(--zb-sidepanel-title-line-height);
    color: var(--zb-sidepanel-title-color);
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: var(--zb-sidepanel-title-height, 50px);
  }

  & .zb-sidepanel-footer {
    background-color: var(--zb-sidepanel-footer-background-color, #fff);
  }

  & .zb-sidepanel-wrapper {
    position: absolute;

    & .zb-sidepanel-inner-wrapper {
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 9999;
      width: 100vw;
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
    }

    & .zb-sidepanel {
      bottom: var(--zb-sidepanel-wrapper-position-bottom);
      top: var(--zb-sidepanel-wrapper-position-top);
      transform: var(--zb-sidepanel-wrapper-transform);
    }

    & .zb-sidepanel-body {
      max-height: inherit;
    }

    & .zb-sidepanel-background {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--zb-sidepanel-backdrop);
      pointer-events: all;
    }
  }
}
