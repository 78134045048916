/* Theme specific overrides */
:--theme {
  /* Footer */

  & .zb-footer-nav-item {
    display: block;
    margin-bottom: var(--zb-footer-nav-item-margin-bottom);

    &:last-child {
      margin-bottom: var(--zb-footer-nav-item-last-margin-bottom);
    }
  }

  & .zb-footer-text {
    display: block;
    margin-top: var(--zb-footer-text-margin-top);
  }

  /* Mobile Masthead*/

  & .zb-is-mobile-masthead {
    box-sizing: border-box;
    position: relative;
    height: var(--zb-is-mobile-masthead-height, 48px);
    background: var(--zb-masthead-background, blue);
  }

  & .zb-is-mobile-masthead-brand-logo {
    display: inline-block;
    position: relative;
    vertical-align: top;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-right: 16px;
    height: var(--zb-is-mobile-masthead-logo-height, 0);
    width: var(--zb-is-mobile-masthead-logo-width, 0);
    background-image: var(--zb-is-mobile-masthead-logo, none);

    &.zb-is-mobile-masthead-brand-logo-asset {
      background-image: var(--zb-is-mobile-masthead-logo-asset, none);
      background-repeat: no-repeat;
    }
  }

  & .zb-masthead-logout,
  & .zb-masthead-toggler {
    background: var(--zb-sea-blue);
  }

  /* Core-css */

  & .zb-button:focus {
    outline: 2px solid var(--zb-focus);
    outline-offset: 2px;
    box-shadow: none;
  }

  & .zb-button-primary {
    --zb-button-primary-width: 32px;

    /* render the 2 rounded sides of the buttons with pseudo elements
       so that the button width can increase on hover without affecting
       the layout */
    &::after,
    &::before {
      position: relative;
      display: block;
      content: "";
      background: var(--zb-button-primary-background-color);
      width: var(--zb-button-primary-width);
      height: calc(
        var(--zb-button-font-size) * var(--zb-button-primary-line-height) +
          var(--zb-button-primary-padding-top) * 2
      );
      margin-top: calc(- var(--zb-button-primary-padding-top) * 2);
      margin-left: calc(- var(--zb-button-primary-width));
      top: var(--zb-button-primary-padding-top);
      transition: left 0.1s;
    }

    &::before {
      float: left;
      left: 0;
      border-top-left-radius: var(--zb-button-border-radius);
      border-bottom-left-radius: var(--zb-button-border-radius);
    }

    &::after {
      float: right;
      left: var(--zb-button-primary-width);
      border-top-right-radius: var(--zb-button-border-radius);
      border-bottom-right-radius: var(--zb-button-border-radius);
    }

    &:disabled::after,
    &:disabled::before,
    &.zb-button-is-disabled::after,
    &.zb-button-is-disabled::before {
      display: none;
    }
  }

  & .zb-button-primary:hover {
    &::after,
    &::before {
      background: var(--zb-button-primary-background-color-rollover);
    }

    &::before {
      left: -4px;
    }

    &::after {
      left: 36px;
    }
  }

  & .zb-button-primary:disabled:hover,
  & .zb-button-primary.zb-button-is-disabled:hover {
    border: var(--zb-button-primary-border-disabled);
    margin-left: 0;
    margin-right: 0;
  }

  & .zb-button-with-icon-before,
  & .zb-button-with-icon-after {
    & > .zb-icon {
      color: var(--zb-sea-blue);
    }
    &:hover > .zb-icon {
      color: var(--zb-sea-blue);
    }
  }

  /* Form-elements */

  & .zb-checkbox-labelled-is-focused {
    & .zb-checkbox {
      border: var(--zb-checkbox-border);

      &.zb-checkbox-is-checked {
        border: var(--zb-checkbox-checked-border);
      }
    }
  }

  & .zb-checkbox-labelled-is-error {
    & .zb-checkbox {
      border: var(--zb-checkbox-border);
      background: var(--zb-checkbox-background);

      &.zb-checkbox-is-checked {
        background: var(--zb-checkbox-checked-background);
        border: var(--zb-checkbox-checked-border);

        & .zb-icon {
          color: var(--zb-checkbox-checked-tick-color);
        }
      }
    }
  }

  & .zb-radio-button-labelled-is-focused {
    & .zb-radio-button.zb-radio-button-is-checked {
      border: var(--zb-radio-button-selected-border);
    }
  }

  & .zb-radio-button-labelled-is-error {
    & .zb-radio-button {
      border: var(--zb-radio-button-border);
      background: var(--zb-white);

      &.zb-radio-button-is-selected {
        border: var(--zb-radio-button-selected-border);
      }

      & .zb-radio-button-circle {
        background: var(--zb-radio-button-circle-color);
      }
    }
  }

  & .zb-file-input-is-focused {
    & .zb-file-input-label {
      outline: none;
      box-shadow: 0 0 0 1px white, 0 0 0 3px var(--zb-focus);
    }
  }

  /* Icons */

  & .zb-icon {
    display: inline-block;
    height: 16px;
    width: 16px;
    line-height: 16px;
    fill: currentColor;
    color: #00759b;
  }

  & svg.zb-icon use {
    /* IE11 fix for UI lockdown*/
    pointer-events: none;
  }

  & .zb-icon-xsmall {
    height: 16px;
    width: 16px;
    line-height: 16px;
  }

  & .zb-icon-small {
    height: 20px;
    width: 20px;
    line-height: 20px;
  }

  & .zb-icon-medium {
    height: 24px;
    width: 24px;
    line-height: 24px;
  }

  & .zb-icon-large {
    height: 32px;
    width: 32px;
    line-height: 32px;
  }

  & .zb-icon-xlarge {
    height: 48px;
    width: 48px;
    line-height: 48px;
  }

  /* Nav */

  & .zb-nav-item {
    transition: background-color 0.1s;

    /* underline */
    &.hover .zb-nav-item-label::after,
    &.zb-nav-item-is-selected .zb-nav-item-label::after {
      content: "";
      position: absolute;
      bottom: 7px;
      left: 12px;
      right: 12px;
      height: 4px;
      border-radius: 2px;
      background: var(--zb-white);
    }

    &.zb-nav-item-is-open .zb-nav-item-label::after {
      display: none;
    }
  }

  & .zb-nav-item-label {
    &:hover {
      color: var(--zb-nav-menu-item-label-color);
    }

    & .zb-icon {
      color: inherit;
    }

    & .zb-nav-item-right-icon {
      color: var(--zb-link-color);
    }
  }

  & .zb-nav-item-right-icon {
    display: none;
  }

  /* Notification */

  & .zb-notification.zb-notification-large {
    padding-bottom: 8px;

    & .zb-notification-inner {
      min-height: var(--zb-notification-large-min-height) - 8px; /* - padding-bottom */
    }

    & .zb-notification-inner:after {
      position: absolute;
      content: "";
      left: -2px;
      right: -2px;
      bottom: -18px;
      box-sizing: border-box;
      border: 8px solid transparent;
    }

    &.zb-notification-status-info .zb-notification-inner:after {
      border-top: 8px solid color-mod(var(--zb-dark-grey-2) shade(10%));
    }

    &.zb-notification-status-success .zb-notification-inner:after {
      border-top: 8px solid color-mod(var(--zb-green) shade(10%));
    }

    &.zb-notification-status-warning .zb-notification-inner:after {
      border-top: 8px solid color-mod(var(--zb-gold) shade(10%));
    }

    &.zb-notification-status-error .zb-notification-inner:after {
      border-top: 8px solid color-mod(var(--zb-red) shade(10%));
    }
  }

  & .zb-card {
    & .zb-notification-to-edges {
      &.zb-notification {
        margin-left: calc(0 - var(--zb-card-padding));
        margin-right: calc(0 - var(--zb-card-padding));
      }

      &.zb-notification-small {
        & .zb-notification-inner {
          padding-left: calc(
            var(--zb-card-padding) +
              var(--zb-notification-small-icon-padding-right) +
              var(--zb-notification-small-icon-size)
          );
          padding-right: var(--zb-card-padding);
        }
      }

      &.zb-notification-medium {
        & .zb-notification-inner {
          padding-left: calc(
            var(--zb-card-padding) +
              var(--zb-notification-medium-icon-padding-right) +
              var(--zb-notification-medium-icon-size)
          );
          padding-right: var(--zb-card-padding);
        }
      }

      &.zb-notification-large {
        & .zb-notification-inner {
          padding-left: calc(
            var(--zb-card-padding) +
              var(--zb-notification-large-icon-padding-right) +
              var(--zb-notification-large-icon-size)
          );
          padding-right: var(--zb-card-padding);
        }
      }

      & .zb-notification-icon {
        left: var(--zb-card-padding);
      }
    }

    & .zb-notification-inside-card {
      max-width: var(--zb-notification-width-inside-card);
    }
  }

  /* progress-indicator */

  & .zb-progress-indicator .zb-icon {
    color: #ffffff;
  }

  /* Split-button */

  & .zb-split-button-primary {
    & .zb-split-button-label {
      border-top-left-radius: var(--zb-split-button-primary-border-radius);
      border-bottom-left-radius: var(--zb-split-button-primary-border-radius);
    }
    & .zb-split-button-icon {
      border-top-right-radius: var(--zb-split-button-primary-border-radius);
      border-bottom-right-radius: var(--zb-split-button-primary-border-radius);
    }
  }

  & .zb-split-button-secondary {
    & .zb-split-button-label {
      border-top-left-radius: var(--zb-split-button-secondary-border-radius);
      border-bottom-left-radius: var(--zb-split-button-secondary-border-radius);
    }
    & .zb-split-button-icon {
      border-top-right-radius: var(--zb-split-button-secondary-border-radius);
      border-bottom-right-radius: var(
        --zb-split-button-secondary-border-radius
      );
    }
  }

  & .zb-split-button-secondary:not(.zb-split-button-is-disabled) {
    &:hover,
    &.zb-split-button-is-focused {
      & .zb-split-button-icon {
        padding: var(--zb-split-button-secondary-icon-hover-padding);
        border: var(--zb-split-button-secondary-hover-border);
      }

      & .zb-split-button-label {
        padding: var(--zb-split-button-secondary-label-hover-padding);
        border: var(--zb-split-button-secondary-hover-border);
        border-right: 1px solid var(--zb-split-button-secondary-divider-color);
      }
    }
  }

  /* Toggle */

  & .zb-toggle-switch-button-inner {
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    top: -1px;
    left: -1px;
    width: var(--zb-toggle-switch-size);
    height: var(--zb-toggle-switch-size);
    background: var(--zb-toggle-switch-background-color);
    border: 1px solid var(--zb-light-blue-2);
    vertical-align: bottom;
    margin-left: 0;
    transition: margin-left 0.1s;
    border-radius: 12px;
  }

  & .zb-toggle-switch:hover .zb-toggle-switch-button-inner {
    border: 2px solid var(--zb-light-blue-2);
  }

  & .zb-toggle-switch.zb-toggle-is-pressed .zb-toggle-switch-button-inner {
    background: var(--zb-light-blue-2);
    margin-left: calc(
      var(--zb-toggle-switch-button-width) - var(--zb-toggle-switch-size)
    );
  }

  &
    .zb-toggle-switch.zb-toggle-is-pressed:hover
    .zb-toggle-switch-button-inner {
    background: color-mod(var(--zb-light-blue-2) shade(20%));
    border-color: color-mod(var(--zb-light-blue-2) shade(20%));
  }

  /* override default box-shadow ouline by standard outline */
  & .zb-toggle-switch:focus {
    outline: 2px solid var(--zb-focus);
    outline-offset: 2px;
    box-shadow: none;
  }
}
