@import '@zambezi/sdk/src/modal/css/base.css';
@import '@zambezi/sdk/src/modal/css/theme.css';

:--theme {
  & .zb-modal-close {
    padding: 4px;
  }

  & .zb-modal-card-title {
    display: flex;
    justify-content: center;
    min-height: 64px;
    flex-direction: column;
    line-height: 1.15em;
  }

  & .zb-modal-card-foot-actions {
    margin: calc(0 - var(--zb-modal-actions-margin, 10px));

    & .zb-button,
    & .zb-modal-action {
      margin: var(--zb-modal-actions-margin, 10px);
      font-size: var(--zb-modal-body-font-size);
    }
  }

  & .zb-modal-centered {
    & .zb-modal-card-title,
    & .zb-modal-body {
      text-align: center;
    }

    & .zb-modal-card-foot-actions {
      justify-content: space-around;
    }
  }

  & .zb-modal-without-header {
    & .zb-modal-card {
      padding: 20px 0;
      text-align: center;
    }
    & .zb-modal-close {
      top: var(--zb-modal-close-offset-top);
      right: var(--zb-modal-close-offset-right);
    }
    & .zb-modal-card-head {
      padding: 0;
      border-bottom: none;

      & .zb-modal-close {
        top: 10px;
      }
    }
  }
}

@media (--mobile-viewport) {
  :--theme {
    & .zb-modal {
      padding: 20px;
    }

    & .zb-modal-card {
      height: auto;
      max-height: 80vh;
    }

    & .zb-modal-card-foot-actions {
      flex-direction: row;

      & .zb-button {
        flex: 1 0 auto;
      }
    }
  }
}
