@import '@zambezi/sdk/src/toggle/css/base.css';
@import '@zambezi/sdk/src/toggle/css/theme.css';

:--theme {
  & .zb-toggle {
    &.zb-toggle-is-disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &.zb-toggle-switch {
      &.zb-toggle-is-disabled {
        & .zb-toggle-switch-button-inner {
          border: var(--zb-toggle-switch-border);
        }
      }
    }
  }
}
