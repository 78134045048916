@import "@zambezi/sdk/src/button-group/css/base.css";
@import "@zambezi/sdk/src/button-group/css/theme.css";

:--theme {
  & .zb-button-group-item {
    &:first-of-type {
      padding-left: calc(2 * var(--zb-button-group-item-horizontal-padding));
    }
    &:last-of-type {
      padding-right: calc(2 * var(--zb-button-group-item-horizontal-padding));
    }
  }

  & .zb-button-group-item-is-selected {
    margin: -1px 0;
    border-top: 1px solid var(--zb-button-group-item-selected-background);
    border-bottom: 1px solid var(--zb-button-group-item-selected-background);
  }
}

@media (--mobile-viewport) {
  :--theme {
    & .zb-button-group {
      display: flex;
    }

    & .zb-button-group-item {
      flex: 1 1 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-around;
      line-height: 1.25;
      
      &:first-of-type {
        padding-left: var(--zb-button-group-item-padding);
      }
      &:last-of-type {
        padding-right: var(--zb-button-group-item-padding);
      }
    }
  }
}
