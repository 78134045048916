@import "@zambezi/sdk/src/tab-bar/css/base.css";
@import "@zambezi/sdk/src/tab-bar/css/theme.css";

:--theme {
  & .zb-tab-bar-item-container {
    display: flex;
    flex-direction: row;
  }

  & .zb-tab-bar-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 1.25;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }

  & .zb-tab-bar-item-is-selected {
    &:hover {
      text-decoration: none;
    }
  }

  & .zb-tab-bar-item-is-disabled {
    color: var(--zb-tab-bar-text-color-disabled);
    cursor: default;
    background-color: var(--zb-tab-bar-background-disabled);
    &:hover {
      text-decoration: none;
    }
  }
}
