@import '@zambezi/sdk/src/date-picker/css/base.css';
@import '@zambezi/sdk/src/date-picker/css/theme.css';

:--theme {
  & .zb-month-calendar {
    max-width: var(--zb-calendar-width);
  }

  & .zb-date-picker-dropdown {
    top: calc(100% + var(--zb-datepicker-dropdown-top));
  }

  & .zb-date-picker .pika-button {
    width: var(--zb-datepicker-button-size, var(--zb-calendar-item-size));
    height: var(--zb-datepicker-button-size, var(--zb-calendar-item-size));
    &.zb-date-picker-day-is-focused {
      color: var(--zb-calendar-item-color);
    }
  }

  & .zb-date-picker .pika-button.zb-date-picker-day-is-focused,
  & .zb-month-calendar-month-is-focused,
  & .zb-month-calendar-month:focus {
    border-color: transparent;
    background: transparent;
    border: 2px solid var(--zb-focus);
    color: var(--zb-calendar-item-color);

    &:hover {
      color: var(--zb-calendar-item-hover-color);
    }

    &.zb-month-calendar-month-is-selected {
      background: var(--zb-calendar-item-selected-background);
      color: var(--zb-white);

      &.zb-month-calendar-month-is-current {
        border: 2px solid var(--zb-focus);
      }
    }
  }

  & .zb-date-picker .is-selected .pika-button {
    background-color: var(--zb-calendar-item-selected-background);
    color: var(--zb-white);

    &:hover {
      color: var(--zb-calendar-item-selected-color);
    }
  }

  & .zb-date-picker {
    & .pika-title {
      line-height: var(--zb-datepicker-title-height);
    }

    & .pika-table tr:first-of-type td {
      padding-top: var(--zb-calendar-margin-top);
    }

    & .pika-table tr:last-of-type td {
      padding-bottom: var(--zb-calendar-margin-bottom);
    }
  }

  & .zb-month-calendar-month {
    font-size: var(--zb-calendar-font-size);
  }

  & .zb-date-picker .pika-table th {
    border-bottom: var(--zb-calendar-divider);
    color: var(--zb-calendar-header-color);
  }

  & .zb-month-calendar-month-is-disabled {
    &.zb-month-calendar-month:hover,
    &.zb-month-calendar-month:focus {
      background: var(--zb-calendar-item-hover-background);
    }
  }

  & .zb-date-stepper {
    padding: var(--zb-date-stepper-padding);
    position: relative;
  }

  & .zb-date-stepper-button {
    top: var(--zb-date-stepper-button-top);
  }

  & .zb-date-stepper-prev-button {
    left: var(--zb-date-stepper-button-left);
  }

  & .zb-date-stepper-next-button {
    right: var(--zb-date-stepper-button-right);
  }
}

@media (--mobile-viewport) {
  :--theme {
    & .zb-date-picker .pika-button {
      margin: 4px;
    }
  }
}
