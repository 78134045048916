:root {
  --zb-purple: #42145F;
  --zb-dark-magenta: #AD1982;
  --zb-aqua-digital: #009FAC;
  --zb-dark-aqua: #1D7B8A;
  --zb-violet: #6F2C91;
  --zb-blue: #516CB3;
  --zb-dark-grey: #333333;
  --zb-grey: #C9C6C6;
  --zb-dark-grey-2: #666666;
  --zb-light-grey: #F5F5F5;
  --zb-light-grey-2: #EEEEEE;
  --zb-focus: #4DAAE9;
  --zb-white: #FFFFFF;
  --zb-black: #000000;
  --zb-gold: #FBBA20;
  --zb-red: #E60303;
  --zb-rose: #CE3B57;
  --zb-green: #429448;
}