@import "@zambezi/sdk/src/slider/css/base.css";
@import "@zambezi/sdk/src/slider/css/theme.css";

:--theme {
  & .zb-slider {
    & .zb-slider-input::-webkit-slider-thumb {
      width: 24px;
      height: 24px;
      margin-top: -10px;
      background-position: 50%;

      &:hover {
        background-position: 50%;
      }
    }
    &.zb-slider-is-disabled {
      & .zb-slider-input::-webkit-slider-thumb:hover {
        background-position: 50%;
      }

      & .zb-slider-decrease-button:hover,
      & .zb-slider-increase-button:hover {
        padding: 1px;
      }
    }

    & .zb-slider-decrease-button,
    & .zb-slider-increase-button {
      width: 24px;
      height: 24px;

      & .zb-icon {
        margin-top: 3px;
      }

      &:hover {
        & .zb-icon {
          margin-top: 2px;
        }
      }
    }

    & .zb-slider-container {
      height: 24px;
      width: calc(100% - 2 * 40px);

      & .zb-slider-input {
        height: 24px;
      }

      & .zb-slider-progress-bar-wrapper {
        top: 10px;
        right: 24px;
      }
    }
  }
}
