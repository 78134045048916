@import "@zambezi/sdk/src/core-css/base/masthead.css";

:--theme {
  & .zb-masthead-container {
    background: var(--zb-masthead-background, blue);
  }

  & .zb-masthead {
    display: flex;
    max-width: var(--zb-masthead-max-width);
    margin: 0 auto;
    padding-left: var(--zb-masthead-padding);
    padding-right: var(--zb-masthead-padding);
    justify-content: space-between;
    align-items: center;

    & .zb-masthead-nav-links {
      display: flex;
      align-items: center;
      display: none;
    }

    & .zb-masthead-logout,
    & .zb-masthead-toggler {
      display: flex;
      padding-left: var(--zb-masthead-nav-link-icon-padding);
      padding-right: var(--zb-masthead-nav-link-icon-padding);
      margin-right: calc(0 - var(--zb-masthead-padding));
      color: var(--zb-white);
      height: var(--zb-masthead-height);
      text-decoration: none;
      margin-left: var(--zb-masthead-nav-link-icon-padding);
      align-items: center;
      max-width: var(--zb-masthead-logout-min-width);
      font-size: var(--body-small-font-size);
      box-sizing: content-box;

      & .zb-icon {
        color: var(--zb-white);
        margin-left: var(--zb-masthead-nav-link-icon-margin-left);
      }
    }

    & .zb-masthead-nav-toggle {
      display: none;

      &:checked + .zb-masthead-nav,
      & + .zb-masthead-nav.zb-masthead-nav-focus-within,
      & + .zb-masthead-nav:focus-within {
        & + .zb-masthead-toggler {
          background: var(--zb-masthead-toggle-open-background, inherit);
        }
      }
    }
    & .zb-masthead-toggler {
      display: none;
      padding-left: 0;
      padding-right: 0;
      justify-content: center;
      width: var(--zb-masthead-height);
      height: var(--zb-masthead-height);
      background: var(--zb-masthead-toggle-closed-background, inherit);
    }

    & .zb-masthead-brand-name {
      flex: 1 0 auto;
    }

    & .zb-masthead-brand-logo,
    & .zb-masthead-brand-name,
    & .zb-masthead-nav-item {
      display: inline-block;
      position: relative;
      vertical-align: top;
      top: 0;
      transform: none;
    }
    & [class^="zb-icon"] {
      flex-shrink: 0;
      display: block;
      color: var(--zb-white);
    }
    & .zb-masthead-nav {
      float: none;
      height: auto;
    }
    & .zb-masthead-brand-logo {
      background-repeat: no-repeat;
    }

    & + .zb-nav {
      border-top: 1px solid color-mod(var(--zb-white) a(20%));
      background: var(--zb-masthead-background, blue);
      max-width: var(--zb-masthead-max-width);
      margin: 0 auto;
      padding-left: var(--zb-masthead-padding);
      padding-right: var(--zb-masthead-padding);
    }

    & .zb-nav {
      display: none;
    }
  }
}

@media (--mobile-viewport) {
  :--theme {
    & .zb-masthead {
      & .zb-masthead-nav {
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        overflow: hidden;
        position: absolute;
        top: var(--zb-masthead-height);
        right: 0;
        background-color: var(--zb-masthead-background, blue);
        box-shadow: -50px 0 0 0 rgba(0, 0, 0, 0.8);
        z-index: 1;
      }
      & .zb-masthead-nav-toggle {
        &:checked + .zb-masthead-nav,
        & + .zb-masthead-nav.zb-masthead-nav-focus-within,
        & + .zb-masthead-nav:focus-within {
          height: calc(100vh - var(--zb-masthead-height));
          width: calc(100% - 50px);
          clip: inherit;
          overflow-y: auto;
        }
      }

      & .zb-masthead-toggler,
      & .zb-masthead-nav-links {
        display: flex;
      }

      & .zb-masthead-nav-item {
        padding: 5px 20px;
        color: var(--zb-nav-item-open-color);
        background: var(--zb-nav-item-open-background);
        display: block;

        &:hover {
          color: var(--zb-nav-item-open-color);
          background: var(--zb-nav-menu-item-hover-background);
        }

        &:first-of-type {
          padding-top: 20px;
        }
        &:last-of-type {
          padding-bottom: 20px;
        }
      }

      & .zb-nav-vertical .zb-nav-item-label > .zb-nav-item-right-icon {
        display: inline-block;
        margin-top: 16px;
      }

      & .zb-nav-menu-group-label {
        border-bottom: 1px solid var(--zb-grey);
      }

      & .zb-nav-item,
      & .zb-nav-menu-item {
        color: var(--zb-nav-item-open-color);
        background: inherit;
        display: block;

        & .zb-nav-item-label,
        & .zb-nav-menu-item-label {
          color: inherit;
          border-bottom: 1px solid var(--zb-grey);

          & .zb-icon {
            color: var(--zb-link-color);
          }
        }

        &:hover {
          color: var(--zb-nav-item-open-color);
          background: var(--zb-nav-menu-item-hover-background);
          & .zb-nav-item-label {
            color: inherit;
          }
        }
      }

      & + .zb-nav {
        display: none;
      }

      & .zb-nav-menu-group-label,
      & .zb-nav-vertical .zb-nav-item-label,
      & .zb-nav-menu-item-label {
        display: flex;
        justify-content: center;
        height: 50px;
        flex-direction: column;
        padding: 0 20px;
      }

      & .zb-nav-menu-group-label,
      & .zb-nav-vertical span.zb-nav-item-label,
      & span.zb-nav-menu-item-label {
        flex-direction: row;
        justify-content: space-between;
        line-height: 50px;
      }

      &
        span.zb-nav-menu-item-label[aria-expanded="true"]
        .zb-icon-chev-down-xsmall {
        transform: scaleY(-1); /* flip the icon upside down when expanded */
      }

      & .zb-nav-menu,
      & .zb-nav-submenu {
        position: static;
        box-shadow: unset;
        padding: 0;
      }

      & .zb-nav-menu-item-label,
      & .zb-nav-menu-group-label {
        padding-left: 30px;
        padding-right: 20px;
      }

      & .zb-nav-submenu {
        padding-left: 30px;

        &.zb-nav-submenu-is-open {
          border-bottom: 1px solid var(--zb-grey);
        }

        & .zb-nav-menu-item-label {
          padding-left: 0;
          padding-right: 0;
        }

        & .zb-nav-menu-item:last-of-type {
          & .zb-nav-menu-item-label {
            border-bottom: none;
          }
        }
      }
      & .zb-nav {
        background-color: var(--zb-nav-item-open-background);
      }

      & .zb-nav,
      & .zb-nav-menu-is-open,
      & .zb-nav-submenu-is-open,
      & .zb-nav-menu-group {
        display: block;
      }
    }
  }
}
