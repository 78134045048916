@import "@zambezi/sdk/src/accordion/css/base.css";
@import "@zambezi/sdk/src/accordion/css/theme.css";

:--theme {
  & .zb-accordion {
    & .zb-accordion .zb-accordion-is-active,
    & .zb-accordion-is-active {
      border-bottom: var(--zb-accordion-separator);
    }

    & .zb-accordion-content {
      font-size: var(--zb-accordion-content-font-size);
    }

    & .zb-accordion-header-icon {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
    & .zb-accordion-header {
      display: flex;
      justify-content: center;
      min-height: var(--zb-accordion-header-height, 65px);
      flex-direction: column;
      line-height: 1.15em;

      & .zb-accordion-header-icon {
        position: absolute;
        right: 0;
      }
    }

    & .zb-accordion {
      & .zb-accordion-header {
        min-height: var(--zb-accordion-nested-header-height, 50px);
        font-size: var(--zb-accordion-nested-header-font-size);

        & .zb-accordion-header-icon {
          left: var(--zb-accordion-header-icon-margin-right);
          margin-right: 0;
          right: auto;
          width: 12px;
          height: 12px;
          line-height: 12px;
        }
      }
    }
  }
}
