@import "@zambezi/sdk/src/progress-indicator/css/base.css";
@import "@zambezi/sdk/src/progress-indicator/css/theme.css";

@media (--mobile-viewport) {
  :root {
    --zb-step-size: 20px;
    --zb-step-size-large: 32px;
    --zb-step-line-height: 1px;
  }
  :--theme {
    /* Hide from display, but keep for screen-readers */
    & .zb-step-description {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      width: 1px;
    }

    & .zb-progress-indicator .zb-icon {
      width: var(--zb-progress-indicator-icon-size);
      height: var(--zb-progress-indicator-icon-size);
      line-height: var(--zb-progress-indicator-icon-size);
    }

    & .zb-steps {
      counter-reset: zb-step;
      position: relative;

      /* Display the total count */
      &:after {
        content: counter(zb-step);
        position: absolute;
        font-size: var(--zb-progress-indicator-step-font-size);
        top: calc(var(--zb-step-size-large) - 1px + var(--display-3-font-size));
        right: 24px;
        color: var(--zb-step-description-color);
        speak: none;
      }
    }

    & .zb-steps .zb-step {
      counter-increment: zb-step;

      & .zb-step-index {
        font-size: var(--zb-progress-indicator-step-font-size);
      }

      &.zb-step-is-selected {
        & .zb-step-description {
          text-align: left;
          left: 24px;
          right: calc(24px + 12px); /* leave space for the total count */
          transform: translateY(var(--display-3-font-size));
          /* Make it visible for everybody */
          width: auto;
          height: auto;
          clip: auto;

          &:after {
            color: var(--zb-step-description-color);
            content: counter(zb-step) " of ";
            float: right;
            speak: none;
          }
        }
        & .zb-step-inner:after {
          left: auto;
          display: block;
          width: var(--zb-step-size-large);
        }
      }
    }

    & .zb-steps .zb-step .zb-step-inner,
    & .zb-steps .zb-step {
      position: static;
    }
  }
}

:--theme {
  & .zb-progress-indicator {
    padding-bottom: var(--zb-step-size-large);
    margin-bottom: 0;
  }
}
