@import "@zambezi/sdk/src/core-css/base/left-hand-nav.css";

:--theme {
  & .zb-left-hand-nav {
    & .zb-left-hand-nav-header {
      background: var(--zb-left-hand-nav-header-background, --zb-white);
      color: var(--zb-left-hand-nav-header-color);
      border-top: var(--zb-left-hand-nav-border, none);
      font-size: var(--zb-left-hand-nav-font-size);

      &:last-child {
        border-top: none;
      }
    }

    & .zb-left-hand-nav-item {
      &.zb-left-hand-nav-item-is-selected {
        & .zb-left-hand-nav-item-link {
          font-weight: bold;
        }
      }
    }

    & .zb-left-hand-nav-item {
      font-size: var(--zb-left-hand-nav-font-size);

      &:disabled,
      &.disabled {
        opacity: 0.3;
      }
    }

    & ul:last-child {
      & .zb-left-hand-nav-item:last-child {
        border-bottom: none;
      }
    }

    &.zb-nav {
      border: none;
    }

    & .zb-nav-menu,
    & .zb-nav-submenu {
      position: static;
      padding: 0;
      box-shadow: none;
    }

    & .zb-nav-menu-group,
    & .zb-nav-menu.zb-nav-menu-is-open {
      display: block;
    }

    & .zb-nav-item.zb-nav-item-is-open,
    & .zb-nav-item.zb-nav-item-is-open.zb-nav-item-is-selected {
      & > .zb-nav-item-label {
        background: var(--zb-left-hand-nav-item-is-selected-background);
        color: var(--zb-left-hand-nav-item-is-selected-color);

        & .zb-nav-item-right-icon {
          color: inherit;
        }
      }
    }

    & .zb-nav-item:hover .zb-nav-item-label {
      & .zb-nav-item-right-icon {
        color: inherit;
      }
    }

    & .zb-nav-item {
      background: var(--zb-left-hand-nav-item-background, none);

      & .zb-nav-item-label {
        color: var(--zb-left-hand-nav-item-color);
      }
    }

    & .zb-nav-submenu {
      border-left: 30px solid var(--zb-left-hand-nav-item-background);

      & .zb-nav-menu-item-label {
        padding-left: 0;
        padding-right: 0;
      }
    }

    & .zb-nav-menu-group-label,
    & .zb-nav-menu-item,
    & .zb-nav-menu-item-label[aria-expanded="true"],
    & .zb-nav-item {
      border-bottom: var(--zb-left-hand-nav-border, none);
    }

    & .zb-nav-menu-is-open {
      border-top: var(--zb-left-hand-nav-border, none);
    }

    & .zb-nav-menu-item,
    & .zb-nav-item {
      &:last-of-type {
        border-bottom: none;
      }
    }

    & .zb-nav-item-label {
      font-size: var(--zb-left-hand-nav-font-size);
    }

    &.zb-nav .zb-left-hand-nav-header,
    & .zb-nav-menu-item-label,
    & .zb-nav-menu-group-label {
      padding: var(--zb-nav-item-padding);
    }

    & .zb-nav-menu-item-label {
      &.zb-nav-menu-item-is-selected {
        & .zb-left-hand-nav-item-link {
          font-weight: bold;
        }
      }
    }

    & .zb-nav-submenu.zb-nav-submenu-is-open {
      display: block;
    }

    & .zb-nav-menu-item-label {
      & .zb-nav-menu-item-open-icon {
        margin: 0;
      }
    }
  }
}
