@import '@zambezi/sdk/src/core-css/base/breadcrumbs.css';

:--theme {
  & .zb-breadcrumb {
    padding: 4px 0 4px 4px;
    font-size: var(--zb-breadcrumb-font-size, var(--body-font-size));

    &::after {
      height: var(--zb-breadcrumbs-arrow-size);
      width: var(--zb-breadcrumbs-arrow-size);
      margin-left: 2px;
    }
  }

  & .zb-breadcrumb-is-current {
    padding-right: 4px;
  }
}
