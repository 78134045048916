@import '@zambezi/sdk/src/core-css/base/loader.css';

:--theme {
  & .zb-loader-text {
    color: var(--zb-loader-text-color, black);
    display: block;
    margin-top: 0;
    line-height: 1.5rem;
  }

  & .zb-loader-block.zb-loader-block-2rd {
    align-items: flex-start;

    & .zb-loader-content {
      position: relative;
      display: block;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
