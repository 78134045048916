@import '@zambezi/sdk/src/split-button/css/base.css';
@import '@zambezi/sdk/src/split-button/css/theme.css';

:--theme {
  & .zb-split-button {
    font-size: var(--zb-split-button-font-size);
    line-height: 1.25;
  }

  & .zb-split-button-label {
    vertical-align: top;
  }
}
