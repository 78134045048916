@import '@zambezi/sdk/src/core-css/base/button.css';

:--theme {
	& .zb-button {
		font-size: var(--zb-button-font-size);
	}

	& .zb-button-link.zb-button-with-icon-before,
	& .zb-button-link.zb-button-with-icon-after {
		& .zb-icon,
		&:hover .zb-icon {
			color: inherit;
		}
	}
}
