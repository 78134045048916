@import '@zambezi/sdk/src/core-css/base/feature-button.css';

:--theme {
	& .zb-feature-button {
		background-color: var(--zb-feature-button-background-color);
		font-size: var(--zb-feature-button-font-size);

		&:disabled,
		&.zb-feature-button-is-disabled {
			cursor: default;
			background-color: var(--zb-feature-button-background-color-disabled);
			color: var(--zb-feature-button-color-disabled);
			opacity: 1;
			border-color: var(--zb-feature-button-background-color-disabled);

			&:hover {
				background: var(--zb-feature-button-background-color-disabled);
			}

			&:focus {
				box-shadow: none;
			}

			& .zb-feature-button-arrowed-content {
				&::after {
					content: var(--zb-feature-button-arrow-icon-disabled-asset);
				}
			}

			& .zb-icon {
				color: var(--zb-dark-grey);
			}
		}

		&.zb-feature-button-multiline {
			padding: var(--zb-feature-button-multiline-padding);
			& > :first-child {
				margin-bottom: 10px;
				display: block;
			}
		}

		& .zb-feature-button-title {
			font-size: var(--zb-feature-button-title-font-size);
		}
	}

	& .zb-feature-button-arrowed-content {
		line-height: var(--zb-feature-button-line-height);

		&::after {
			height: var(--zb-feature-button-arrow-icon-height);
			width: var(--zb-feature-button-arrow-icon-size);
		}
	}
}
