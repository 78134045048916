@import "@zambezi/sdk/src/form-elements/css/base.css";
@import "@zambezi/sdk/src/form-elements/css/theme.css";

:--theme {
  & .zb-checkbox {
    margin-right: calc(var(--zb-checkbox-margin-right) / 2);
  }

  & .zb-checkbox-labelled {
    margin-right: var(--zb-checkbox-margin-right);
  }

  & .zb-checkbox-labelled-is-disabled {
    & .zb-checkbox-label {
      color: var(--zb-checkbox-label-disabled-color, inherit);
    }
  }

  & .zb-checkbox-input + label {
    font-size: var(--zb-checkbox-font-size);
  }

  & .zb-radio-button {
    margin-right: calc(var(--zb-checkbox-margin-right) / 2);
  }

  & .zb-radio-button-labelled {
    & .zb-radio-button {
      margin-right: var(--zb-checkbox-margin-right);
    }
  }

  & .zb-radio-button-input + label {
    font-size: var(--zb-radio-font-size);
  }

  & .zb-file-input {
    border: var(--zb-file-input-border, none);
    background: var(--zb-file-input-background, inherit);
    padding: var(--zb-file-input-padding, 0);
  }

  & .zb-file-input-is-disabled {
    border: var(--zb-file-input-disabled-border, none);
    background: var(--zb-file-input-disabled-background, inherit);
    padding: var(--zb-file-input-disabled-padding, var(--zb-file-input-padding, 0));
  }

  & .zb-file-input-is-focused {
    background: var(--zb-file-input-focused-background, inherit);
    border: var(--zb-file-input-focused-border, none);
    padding: var(--zb-file-input-focused-padding, var(--zb-file-input-padding, 0));
  }

  & .zb-file-input-is-error {
    background: var(--zb-file-input-error-background, inherit);
    border: var(--zb-file-input-error-border, none);
    padding: var(--zb-file-input-error-padding, var(--zb-file-input-padding, 0));
  }

  & .zb-file-input-is-hover {
    background: var(--zb-file-input-hover-background, inherit);
    border: var(--zb-file-input-hover-border, none);
    padding: var(--zb-file-input-hover-padding, var(--zb-file-input-padding, 0));
  }

  & .zb-file-input-is-invalid {
    background-color: var(--zb-file-input-invalid-background-color, inherit);
  }
}
