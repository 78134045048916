@import '@zambezi/sdk/src/pager/css/base.css';
@import '@zambezi/sdk/src/pager/css/theme.css';

:--theme {
  & .zb-pager {
    font-size: var(--zb-pager-font-size);

    & .zb-icon {
      width: var(--zb-pager-icon-size);
      height: var(--zb-pager-icon-size);
    }
  }

  & .zb-pager > .zb-pager-navigation {
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
      & + .zb-pager-text {
        margin-left: 8px;
      }
    }
  }

  & .zb-pager-navigation,
  & .zb-pager-ellipsis {
    min-width: 1.5em;
    box-sizing: border-box;
    text-align: center;
  }
}
