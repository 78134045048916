@import "@zambezi/sdk/src/core-css/base/card.css";

:--theme {
  & .zb-card-body {
    font-size: var(--zb-card-body-font-size);
  }

  & .zb-card-header {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
  }

  & .zb-card-header-title {
    display: flex;
    justify-content: center;
    min-height: 65px;
    flex-direction: column;
    line-height: 1.15em;
    box-sizing: border-box;
  }
}
