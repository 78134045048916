/* Theme declaration */
@custom-selector :--theme .zb-rbs-nonpersonal-standard-theme;

:root {
  /* ================= */
  /* === ACCORDION === */
  /* ================= */

  --zb-accordion-header-background-color: var(--zb-white);
  --zb-accordion-header-text-color: var(--zb-royal-blue);
  --zb-accordion-content-background: var(--zb-white);
  --zb-accordion-content-color: var(--zb-black);
  --zb-accordion-border-color: var(--zb-light-grey-2);
  --zb-accordion-separator: 1px solid color-mod(var(--zb-grey) tint(40%));
  --zb-accordion-header-font-size: var(--display-2-font-size);
  --zb-accordion-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  --zb-accordion-header-padding: 14px 66px 14px 30px;
  --zb-accordion-header-is-last-padding: 0 66px 0 30px;
  --zb-accordion-nested-header-padding: 0 30px 0 52px;
  --zb-accordion-nested-header-font-size: 1rem;
  --zb-accordion-nested-is-last-header-padding: 0 30px 0 52px;
  --zb-accordion-with-padding-nested-header-padding: 0 30px 0 52px;
  --zb-accordion-with-padding-nested-is-last-header-padding: 0 30px 0 52px;
  --zb-accordion-header-icon-margin-right: 30px;
  --zb-accordion-content-font-size: var(--body-font-size);
  --zb-accordion-content-padding: 30px;
  --zb-accordion-nested-content-padding: 20px 30px 20px 47px;
  --zb-accordion-with-padding-nested-content-padding: 20px 30px 20px 47px;
  --zb-accordion-nested-content-max-width: 810px;
  --zb-accordion-nested-header-background-color: color-mod(
    var(--zb-light-grey) tint(60%)
  );
  --zb-accordion-nested-border: 1px solid var(--zb-accordion-separator);
  --zb-accordion-border-radius: 0;
  --zb-accordion-header-focus-outline: 1px solid var(--zb-focus);
  --zb-accordion-header-focus-border: 1px solid var(--zb-focus);

  /* ==================== */
  /* === BUTTON-GROUP === */
  /* ==================== */

  --zb-button-group-padding: 0;
  --zb-button-group-background-color: var(--zb-white);
  --zb-button-group-border: 1px solid var(--zb-grey);
  --zb-button-group-border-radius: 0;
  --zb-button-group-item-font-family: var(--body-font-family);
  --zb-button-group-item-font-size: var(--body-font-size);
  --zb-button-group-item-color: var(--zb-light-blue-2);
  --zb-button-group-item-spacing: 0;
  --zb-button-group-item-border-radius: 0;
  --zb-button-group-item-horizontal-padding: 15px;
  --zb-button-group-item-padding: 12px
    var(--zb-button-group-item-horizontal-padding);
  --zb-button-group-item-selected-background: var(--zb-royal-blue);
  --zb-button-group-item-selected-color: var(--zb-white);
  --zb-button-group-item-hover-color: var(--zb-black);
  --zb-button-group-item-hover-text-decoration: underline;

  /* ================ */
  /* === CORE-CSS === */
  /* ================ */

  --zb-font-family-main: var(--body-font-family);
  --zb-font-size-main: var(--body-font-size);
  --zb-line-height-main: var(--body-line-height);

  --zb-color-text: var(--zb-black);
  --zb-color-focus: var(--zb-focus);

  --zb-link-color: var(--zb-light-blue-2);
  --zb-link-text-decoration: underline;
  --zb-link-hover-color: var(--zb-light-blue-2);
  --zb-link-hover-text-decoration: none;

  --zb-tertiary-cta-color: var(--zb-royal-blue);
  --zb-tertiary-cta-hover-color: var(--zb-royal-blue);

  --zb-heading1-font-family: var(--display-1-font-family);
  --zb-heading1-font-size: var(--display-1-font-size);
  --zb-heading1-font-weight: var(--display-1-font-weight);
  --zb-heading1-line-height: var(--display-1-line-height);
  --zb-heading1-color: var(--zb-royal-blue);
  --zb-heading2-font-family: var(--display-2-font-family);
  --zb-heading2-font-size: var(--display-2-font-size);
  --zb-heading2-font-weight: var(--display-2-font-weight);
  --zb-heading2-line-height: var(--display-2-line-height);
  --zb-heading3-font-family: var(--display-3-font-family);
  --zb-heading3-font-size: var(--display-3-font-size);
  --zb-heading3-font-weight: var(--display-3-font-weight);
  --zb-heading3-line-height: var(--display-3-line-height);
  --zb-heading4-font-family: var(--display-4-font-family);
  --zb-heading4-font-size: var(--display-4-font-size);
  --zb-heading4-font-weight: var(--display-4-font-weight);
  --zb-heading4-line-height: var(--display-4-line-height);
  --zb-heading5-font-family: var(--display-5-font-family);
  --zb-heading5-font-size: var(--display-5-font-size);
  --zb-heading5-font-weight: var(--display-5-font-weight);
  --zb-heading5-line-height: var(--display-5-line-height);
  --zb-heading6-font-family: var(--display-6-font-family);
  --zb-heading6-font-size: var(--display-6-font-size);
  --zb-heading6-font-weight: var(--display-6-font-weight);
  --zb-heading6-line-height: var(--display-6-line-height);

  --zb-body2-small-font-size: var(--body2-small-font-size);
  --zb-body2-small-line-height: var(--body2-small-line-height);
  --zb-body2-small-font-family: var(--body2-small-font-family);
  --zb-body-small-font-size: var(--body-small-font-size);
  --zb-body-small-line-height: var(--body-small-line-height);
  --zb-body-small-font-family: var(--body-small-font-family);
  --zb-body-small-bold-font-size: var(--body-small-bold-font-size);
  --zb-body-small-bold-line-height: var(--body-small-bold-line-height);
  --zb-body-small-bold-font-family: var(--body-small-bold-font-family);
  --zb-body-small-bold-font-weight: var(--body-small-bold-font-weight);
  --zb-text-small-font-size: var(--body-small-font-size);
  --zb-text-small-line-height: var(--body-small-font-size);
  --zb-text-small-font-family: var(--body-small-font-family);

  --zb-input-height: 44px;
  --zb-input-font-size: var(--body-font-size);
  --zb-input-border: 1px solid var(--zb-grey);
  --zb-input-padding: 0 12px;
  --zb-input-border-radius: 0;
  --zb-input-focus-border: 1px solid var(--zb-focus);
  --zb-input-focus-padding: 0 12px;
  --zb-input-focus-box-shadow: 0 0 0 1px var(--zb-focus);
  --zb-input-disabled-border: 1px solid color-mod(var(--zb-grey) tint(40%));
  --zb-input-disabled-padding: 0 12px;
  --zb-input-disabled-background: var(--zb-white);
  --zb-input-disabled-color: color-mod(var(--zb-black) tint(40%));
  --zb-input-error-border: 1px solid var(--zb-red);
  --zb-input-error-box-shadow: 0 0 0 1px var(--zb-red);
  --zb-input-error-padding: 0 12px;
  --zb-input-error-background: var(--zb-white);
  --zb-input-wrapper-line-height: 42px; /* --zb-input-height - (2 * --zb-input-focus-border) */
  --zb-input-wrapper-focus-line-height: 42px;
  --zb-input-wrapper-disabled-line-height: 42px;
  --zb-input-wrapper-error-line-height: 42px;
  --zb-input-combined-focus-padding-right: calc(
    12px - 1px
  ); /* zb-input-focus-padding (horizontal) - --zb-input-border */
  --zb-input-placeholder-color: color-mod(var(--zb-dark-grey-2) tint(10%));
  --zb-textarea-padding: 10px 12px;
  --zb-textarea-focus-padding: 10px 12px;
  --zb-textarea-error-padding: 10px 12px;

  --zb-button-font-size: var(--body-font-size);
  --zb-button-min-width: 96px;
  --zb-button-padding: 0 32px;
  --zb-button-border-radius: 22px;
  --zb-button-icon-before-margin: 0 7px 1px -12px;
  --zb-button-icon-after-margin: 0 -12px 1px 7px;
  --zb-button-primary-line-height: var(--body-line-height);
  --zb-button-primary-padding-top: 12px;
  --zb-button-primary-padding: var(--zb-button-primary-padding-top) 32px;
  --zb-button-primary-background-color: var(--zb-sea-blue);
  --zb-button-primary-foreground-color: var(--zb-white);
  --zb-button-primary-background-color-rollover: var(--zb-light-blue);
  --zb-button-primary-foreground-color-rollover: var(--zb-white);
  --zb-button-primary-background-color-disabled: var(--zb-grey);
  --zb-button-primary-foreground-color-disabled: var(--zb-dark-grey);
  --zb-button-primary-border: none;
  --zb-button-primary-border-rollover: none;
  --zb-button-primary-border-disabled: none;

  --zb-button-secondary-line-height: 1;
  --zb-button-secondary-padding: 13px 32px;
  --zb-button-secondary-padding-rollover: 12px 31px;
  --zb-button-secondary-background-color: transparent;
  --zb-button-secondary-foreground-color: var(--zb-sea-blue);
  --zb-button-secondary-background-color-rollover: transparent;
  --zb-button-secondary-foreground-color-rollover: var(--zb-sea-blue);
  --zb-button-secondary-background-color-disabled: var(--zb-grey);
  --zb-button-secondary-foreground-color-disabled: var(--zb-dark-grey);
  --zb-button-secondary-border: 1px solid var(--zb-sea-blue);
  --zb-button-secondary-border-rollover: 2px solid var(--zb-sea-blue);
  --zb-button-secondary-border-disabled: 1px solid var(--zb-grey);

  --zb-feature-button-line-height: 1;
  --zb-feature-button-min-width: 180px;
  --zb-feature-button-padding: 14px 52px 14px 32px;
  --zb-feature-border-radius: 0;
  --zb-feature-button-background-color: #f5fdfe;
  --zb-feature-button-color: var(--zb-royal-blue);
  --zb-feature-button-border: 1px solid var(--zb-sea-blue);
  --zb-feature-button-arrow-icon: svg-inline(--zb-feature-button-arrow);
  --zb-feature-button-arrow-icon-asset: url(./assets/svg/feature-button-arrow.svg);
  --zb-feature-button-arrow-icon-disabled-asset: url(./assets/svg/feature-button-arrow-disabled.svg);
  --zb-feature-button-arrow-icon-size: 10px;
  --zb-feature-button-arrow-icon-height: 16px;
  --zb-feature-button-arrow-icon-spacing: 10px;
  --zb-feature-button-hover-background: var(--zb-white);
  --zb-feature-button-focus-outline: var(--zb-focus);
  --zb-feature-button-background-color-disabled: var(--zb-grey);
  --zb-feature-button-color-disabled: var(--zb-dark-grey);
  --zb-feature-button-multiline-padding: 20px 52px 20px 32px;
  --zb-feature-button-title-font-size: 20px;
  --zb-feature-button-font-size: var(--body-font-size);

  --zb-masthead-height: 72px;
  --zb-masthead-max-width: 1280px;
  --zb-masthead-padding: 20px;
  --zb-masthead-background: var(--zb-royal-blue);
  --zb-masthead-brand-name-color: var(--zb-white);
  --zb-masthead-brand-name-font-size: var(--display-2-font-size);
  --zb-masthead-logo: svg-inline(--zb-asset-logo);
  --zb-masthead-logo-asset: url(./assets/svg/RBS_logo.svg);
  --zb-masthead-logo-height: 37px;
  --zb-masthead-logo-width: 134px;

  --zb-is-mobile-masthead-height: 48px;
  --zb-is-mobile-masthead-logo-height: 26px;
  --zb-is-mobile-masthead-logo-width: 143px;
  --zb-is-mobile-masthead-logo: svg-inline(--zb-is-mobile-asset-logo);
  --zb-is-mobile-masthead-logo-asset: url(./assets/svg/RBS_logo_small.svg);

  --zb-masthead-nav-link-icon-padding: 20px;
  --zb-masthead-nav-link-icon-margin-left: 5px;
  --zb-masthead-logout-min-width: 71px;

  --zb-masthead-nav-item-padding: 12px;
  --zb-masthead-nav-item-font-size: var(--body-small-font-size);
  --zb-masthead-nav-item-color: color-mod(var(--zb-royal-blue) tint(70%));
  --zb-masthead-nav-item-hover-color: var(--zb-white);
  --zb-masthead-nav-item-text-decoration: none;
  --zb-masthead-nav-item-hover-text-decoration: none;

  --zb-masthead-toggle-closed-background: no-repeat center
    svg-inline(--zb-burger) var(--zb-sea-blue);
  --zb-masthead-toggle-open-background: no-repeat center svg-inline(--zb-close)
    var(--zb-sea-blue);

  --zb-footer-background: var(--zb-royal-blue);
  --zb-footer-nav-item-font-size: var(--body-small-font-size);
  --zb-footer-nav-item-color: color-mod(var(--zb-royal-blue) tint(70%));
  --zb-footer-nav-item-text-decoration: none;
  --zb-footer-nav-item-hover-color: var(--zb-white);
  --zb-footer-nav-item-hover-text-decoration: none;
  --zb-footer-text-color: var(--zb-white);
  --zb-footer-padding: 16px 0 24px;
  --zb-footer-nav-item-margin-bottom: 16px;
  --zb-footer-nav-item-last-margin-bottom: 0;
  --zb-footer-text-margin-top: 0;

  --zb-card-padding: 30px;
  --zb-card-header-background: var(--zb-white);
  --zb-card-title-font-size: var(--display-2-font-size);
  --zb-card-title-font-weight: var(--display-2-font-weight);
  --zb-card-title-color: var(--zb-royal-blue);
  --zb-card-border-radius: 0;
  --zb-card-body-font-size: var(--body-font-size);
  --zb-card-body-padding: var(--zb-card-padding);
  --zb-card-header-border-bottom: 1px solid color-mod(var(--zb-grey) tint(40%));
  --zb-card-header-padding: 0 var(--zb-card-padding);
  --zb-card-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);

  --zb-table-font-size: var(--body-font-size);
  --zb-table-column-header-background: color-mod(
    var(--zb-light-grey) tint(50%)
  );
  --zb-table-column-header-height: auto;
  --zb-table-column-header-padding: 12px 16px;
  --zb-table-column-header-font-weight: normal;
  --zb-table-column-header-font-size: var(--body2-small-font-size);
  --zb-table-column-header-line-height: 1.2;
  --zb-table-column-header-color: var(--zb-dark-grey-2);
  --zb-table-cell-background: var(--zb-white);
  --zb-table-cell-line-height: var(--body-line-height);
  --zb-table-cell-color: var(--zb-black);
  --zb-table-row-border: 1px solid var(--zb-grey);
  --zb-table-row-hover-cell-background: var(--zb-white);

  --zb-nested-table-cell-padding: 8px;
  --zb-nested-table-cell-padding-left: 16px;
  --zb-nested-table-head-padding: 12px 8px;

  --zb-flushed-table-cell-padding: 8px;
  --zb-flushed-table-cell-padding-left: 30px;
  --zb-flushed-table-head-padding: 12px 8px;

  --zb-table-card-header-padding: 14px 30px;
  --zb-table-cell-padding: 12px 16px;
  --zb-table-first-cell-padding: 30px;
  --zb-table-cell-header-color: var(--zb-black);
  --zb-table-cell-checkbox-margin-right: 8px;

  --zb-complex-table-cell-padding: 12px;
  --zb-complex-table-cell-height: 32px;
  --zb-complex-table-column-header-padding: 8px;
  --zb-complex-table-column-header-height: 32px;
  --zb-complex-table-toolbar-padding: 10px 12px;
  --zb-complex-table-toolbar-height: 44px;

  --zb-table-toolbar-select-font-size: var(--body-font-size);
  --zb-table-toolbar-select-height: 40px;
  --zb-table-toolbar-font-size: var(--body-font-size);
  --zb-table-toolbar-select-icon-size: 16px;
  --zb-table-accordion-toggle-size: 16px;

  --zb-scrollbar-background-color: var(--zb-white);
  --zb-scrollbar-track-color: var(--zb-white);
  --zb-scrollbar-track-border-color: var(--zb-grey);
  --zb-scrollbar-thumb-radius: 0.5px;
  --zb-scrollbar-thumb-color: var(--zb-grey);
  --zb-scrollbar-thumb-hover-color: var(--zb-light-blue-2);
  --zb-scrollbar-corner-color: var(--zb-light-grey);

  --zb-breadcrumbs-vertical-spacing: 8px;
  --zb-breadcrumbs-icon: svg-inline(--zb-breadcrumbs-arrow);
  --zb-breadcrumbs-arrow-size: 20px;

  --zb-list-bullet: svg-inline(--zb-asset-list-bullet);

  --zb-overlay-colour: rgba(0, 0, 0, 0.6);

  --zb-brand-logo-background: svg-inline(--zb-asset-brand-logo);
  --zb-brand-logo-height: 88px;
  --zb-brand-logo-width: 318px;

  --zb-loader-size: 48px;
  --zb-loader-text-color: black;
  --zb-loader-inverted-text-color: var(--zb-white);
  --zb-loader-block-inverted-background: rgba(0, 0, 0, 0.6);
  --zb-loader-asset: url(./assets/svg/loader.svg);
  --zb-loader-dark-asset: url(./assets/svg/loader-dark.svg);

  --zb-left-hand-nav-border: 1px solid color-mod(var(--zb-grey) tint(30%));
  --zb-left-hand-nav-font-size: var(--body-font-size);
  --zb-left-hand-nav-header-line-height: 18px;
  --zb-left-hand-nav-header-padding: 21px 30px;
  --zb-left-hand-nav-header-background: color-mod(var(--zb-grey) tint(70%));
  --zb-left-hand-nav-header-color: var(--zb-royal-blue);
  --zb-left-hand-nav-item-line-height: 18px;
  --zb-left-hand-nav-item-padding: 21px 40px;
  --zb-left-hand-nav-item-background: var(--zb-white);
  --zb-left-hand-nav-item-color: var(--zb-royal-blue);
  --zb-left-hand-nav-item-hover-background: var(--zb-royal-blue);
  --zb-left-hand-nav-item-hover-color: var(--zb-white);
  --zb-left-hand-nav-item-is-selected-background: var(--zb-royal-blue);
  --zb-left-hand-nav-item-is-selected-color: var(--zb-white);

  /* =================== */
  /* === DATE-PICKER === */
  /* =================== */

  --zb-calendar-width: 304px;
  --zb-calendar-font-family: var(--body-font-family);
  --zb-calendar-font-size: var(--body-font-size);
  --zb-calendar-border: 1px solid var(--zb-grey);
  --zb-calendar-header-color: var(--zb-dark-grey-2);
  --zb-calendar-header-background: var(--zb-light-grey);
  --zb-calendar-body-background-color: var(--zb-white);
  --zb-calendar-divider: 1px solid color-mod(var(--zb-grey) tint(60%));
  --zb-calendar-margin-top: 12px;
  --zb-calendar-margin-bottom: 12px;
  --zb-calendar-item-size: 40px;
  --zb-calendar-item-padding-top: 0;
  --zb-calendar-item-border-radius: 0;
  --zb-calendar-item-color: var(--zb-dark-grey-2);
  --zb-calendar-item-current-border: 1px solid var(--zb-grey);
  --zb-calendar-item-hover-background: var(--zb-light-grey);
  --zb-calendar-item-hover-color: var(--zb-light-blue-2);
  --zb-calendar-item-hover-border: 1px solid var(--zb-grey);
  --zb-calendar-item-selected-font-weight: normal;
  --zb-calendar-item-selected-background: var(--zb-royal-blue);
  --zb-calendar-item-selected-color: var(--zb-white);
  --zb-calendar-item-disabled-color: color-mod(var(--zb-dark-grey-2) tint(50%));
  --zb-calendar-position-top-offset: -4px;
  --zb-calendar-position-bottom-offset: 4px;
  --zb-calendar-offset-left: -8px;

  --zb-datepicker-dropdown-top: 3px;
  --zb-datepicker-input-border: 1px solid var(--zb-grey);
  --zb-datepicker-input-background-color: var(--zb-white);
  --zb-datepicker-input-focus-border: 1px solid var(--zb-focus);
  --zb-datepicker-input-focus-outline: 1px solid var(--zb-focus);
  --zb-datepicker-input-disabled-border: 1px solid
    color-mod(var(--zb-grey) tint(40%));
  --zb-datepicker-shadow: 1px 1px 3px 0 color-mod(var(--zb-black) tint(50%));

  --zb-datepicker-input-padding: 0 12px 0 12px;
  --zb-datepicker-input-focus-padding: 0 12px 0 12px;
  --zb-datepicker-input-error-padding: 0 12px 0 12px;
  --zb-datepicker-input-disabled-padding: 0 12px 0 12px;
  --zb-datepicker-input-border-radius: 0;
  --zb-datepicker-input-height: 44px;
  --zb-datepicker-input-line-height: 42px;
  --zb-datepicker-input-focus-line-height: 42px;
  --zb-datepicker-input-disabled-line-height: 42px;
  --zb-datepicker-input-error-line-height: 42px;
  --zb-datepicker-days-font-size: var(--body-font-size);
  --zb-datepicker-icon-disabled-color: color-mod(
    var(--zb-light-blue-2) tint(60%)
  );
  --zb-datepicker-icon-error-color: var(--zb-light-blue-2);

  --zb-datepicker-input-error-border: 1px solid var(--zb-red);
  --zb-datepicker-input-error-outline: 1px solid var(--zb-red);
  --zb-datepicker-input-error-background: var(--zb-white);

  --zb-datepicker-input-vertical-align: baseline;

  --zb-datepicker-prev-background: none;
  --zb-datepicker-next-background: none;
  --zb-datepicker-prev-disabled-background: none;
  --zb-datepicker-next-disabled-background: none;
  --zb-datepicker-button-size: 40px;

  --zb-date-stepper-label-color: var(--zb-light-blue-2);
  --zb-date-stepper-button-disabled-color: var(--zb-grey);
  --zb-date-stepper-button-color: var(--zb-light-blue-2);
  --zb-date-stepper-padding: 12px 0 41px 0;
  --zb-date-stepper-button-top: 16px;
  --zb-date-stepper-button-left: 12px;
  --zb-date-stepper-button-right: 12px;

  --zb-calendar-row-padding-left: 12px;
  --zb-calendar-row-padding-right: 12px;
  --zb-calendar-header-font-weight: normal;
  --zb-calendar-header-height: 32px;
  --zb-datepicker-table-margin-top: 0;
  --zb-datepicker-title-height: 24px;
  --zb-datepicker-title-padding: 12px 0;
  --zb-datepicker-title-text-transform: capitalize;
  --zb-calendar-header-text-decoration: underline;
  --zb-calendar-header-hover-text-decoration: none;
  --zb-month-calendar-months-container-padding: 8px 12px;
  --zb-month-calendar-month-margin: 6px 26px;
  --zb-calendar-text-transform: capitalize;

  /* ===================== */
  /* === DROPDOWN-LIST === */
  /* ===================== */

  --zb-lookup-min-height: 44px;
  --zb-lookup-border: 1px solid var(--zb-grey);
  --zb-lookup-padding: 0 12px;
  --zb-lookup-background: var(--zb-white);
  --zb-lookup-border-radius: 0;
  --zb-lookup-focus-border: 1px solid var(--zb-focus);
  --zb-lookup-focus-box-shadow: 0 0 0 1px var(--zb-focus) inset;
  --zb-lookup-error-border: 1px solid var(--zb-red);
  --zb-lookup-error-box-shadow: 0 0 0 1px var(--zb-red) inset;
  --zb-lookup-error-background: var(--zb-white);
  --zb-lookup-error-icon-color: var(--zb-red);
  --zb-lookup-disabled-border: 1px solid color-mod(var(--zb-grey) tint(40%));
  --zb-lookup-disabled-padding: 0 7px;
  --zb-lookup-disabled-background: var(--zb-white);
  --zb-lookup-disabled-color: color-mod(var(--zb-black) tint(40%));
  --zb-lookup-icon-disabled-color: color-mod(var(--zb-sea-blue) tint(60%));
  --zb-lookup-input-min-height: 44px; /* --zb-input-height - (2 * --zb-input-focus-border) */
  --zb-lookup-line-height: 44px; /* --zb-input-height - (2 * --zb-input-focus-border) */
  --zb-lookup-focus-line-height: 40px;
  --zb-lookup-disabled-line-height: 40px;
  --zb-lookup-error-line-height: 40px;
  --zb-lookup-ghost-color: var(--zb-dark-grey-2);
  --zb-lookup-placeholder-color: var(--zb-dark-grey-2);
  --zb-lookup-input-font-size: var(--body-font-size);
  --zb-lookup-input-icon-right: 4px;
  --zb-lookup-input-icon-padding: 8px;

  --zb-select-padding: 0 40px 0 12px;

  --zb-combobox-padding-left: 12px;
  --zb-combobox-padding-right: 40px;
  --zb-combobox-padding: 0 var(--zb-combobox-padding-right) 0
    var(--zb-combobox-padding-left);

  --zb-dropdown-container-padding: 0;
  --zb-dropdown-container-min-width: 104px;
  --zb-dropdown-container-line-height: 1.5;
  --zb-dropdown-container-background: var(--zb-white);
  --zb-dropdown-container-border-color: var(--zb-grey);
  --zb-dropdown-container-border: 1px solid var(--zb-grey);
  --zb-dropdown-container-border-radius: 0;
  --zb-dropdown-container-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  --zb-dropdown-container-max-height: 420px;
  --zb-dropdown-container-arrow-size: 0;
  --zb-dropdown-container-margin: 4px 0 0 0;
  --zb-dropdown-list-group-label-padding: 10px;
  --zb-dropdown-list-group-label-background: color-mod(
    var(--zb-light-grey) tint(50%)
  );
  --zb-dropdown-list-group-label-border-top: 1px solid var(--zb-grey);
  --zb-dropdown-list-group-label-border-bottom: 1px solid var(--zb-grey);
  --zb-dropdown-list-group-label-color: var(--zb-black);
  --zb-dropdown-list-item-font-size: var(--body-font-size);
  --zb-dropdown-list-item-min-height: 44px;
  --zb-dropdown-list-item-padding: 10px 12px;
  --zb-dropdown-list-item-background: var(--zb-white);
  --zb-dropdown-list-item-color: var(--zb-dark-grey-2);
  --zb-dropdown-list-item-border-top: 1px solid var(--zb-grey);
  --zb-dropdown-list-item-border-bottom: 1px solid transparent;
  --zb-dropdown-list-item-hover-color: var(--zb-light-blue-2);
  --zb-dropdown-list-item-hover-padding: 10px 12px;
  --zb-dropdown-list-item-hover-background: var(--zb-light-grey);
  --zb-dropdown-list-item-hover-border-top: 1px solid var(--zb-grey);
  --zb-dropdown-list-item-hover-border-bottom: 1px solid transparent;
  --zb-dropdown-list-item-hover-next-border-top: 1px solid var(--zb-grey);
  --zb-dropdown-list-item-selected-color: var(--zb-white);
  --zb-dropdown-list-item-selected-background: var(--zb-royal-blue);
  --zb-dropdown-list-item-selected-hover-background: color-mod(
    var(--zb-royal-blue) tint(10%)
  );
  --zb-dropdown-list-item-selected-hover-color: var(--zb-white);
  --zb-dropdown-list-item-disabled-border-top: 1px solid var(--zb-grey);
  --zb-dropdown-list-item-disabled-border-bottom: 1px solid transparent;
  --zb-dropdown-list-item-disabled-color: var(--zb-grey);
  --zb-dropdown-list-item-text-highlight-color: var(--zb-black);
  --zb-dropdown-list-item-hover-text-highlight-color: var(--zb-light-blue-2);
  --zb-dropdown-list-item-text-dimmed-color: var(--zb-dark-grey-2);
  --zb-dropdown-list-item-hover-text-dimmed-color: var(--zb-light-blue-2);

  --zb-dropdown-header-padding: 10px 12px;
  --zb-dropdown-header-font-size: var(--body-font-size);
  --zb-dropdown-header-shadow: none;
  --zb-dropdown-header-background: color-mod(var(--zb-grey) tint(70%));
  --zb-dropdown-header-border: 1px solid var(--zb-grey);
  --zb-dropdown-footer-padding: 10px 12px;
  --zb-dropdown-footer-separator: 1px solid var(--zb-grey);
  --zb-dropdown-footer-background: var(--zb-white);
  --zb-dropdown-footer-font-size: var(--body-font-size);

  --zb-select-dropdown-list-item-selected-padding: 10px 30px 10px 12px;
  --zb-select-dropdown-list-item-icon-color: var(--zb-white);
  --zb-select-dropdown-list-item-hover-icon-color: var(--zb-white);

  --zb-multiselect-dropdown-list-item-icon-border: 1px solid var(--zb-grey);
  --zb-multiselect-dropdown-list-item-icon-border-radius: 0;
  --zb-multiselect-dropdown-list-item-icon-size: 22px;
  --zb-multiselect-dropdown-list-item-icon-margin-right: 8px;
  --zb-multiselect-dropdown-list-item-selected-icon-background: var(
    --zb-light-blue-2
  );
  --zb-multiselect-dropdown-list-item-selected-icon-color: var(--zb-white);
  --zb-multiselect-dropdown-list-item-selected-icon-border: 1px solid
    var(--zb-light-blue-2);
  --zb-multiselect-dropdown-list-item-selected-background: var(--zb-white);
  --zb-multiselect-dropdown-list-item-selected-color: var(--zb-black);
  --zb-multiselect-dropdown-list-item-selected-hover-background: var(
    --zb-light-grey
  );
  --zb-multiselect-dropdown-list-item-selected-hover-color: var(
    --zb-light-blue-2
  );
  --zb-dropdown-trigger-icon-size: 16px;

  --zb-select-mobile-arrow-margin-right: 8px;
  --zb-select-mobile-padding-right: 40px;

  /* ============== */
  /* === FLYOUT === */
  /* ============== */

  --zb-flyout-font-size: var(--body-font-size);
  --zb-flyout-padding: 15px;
  --zb-flyout-background: var(--zb-light-grey);
  --zb-flyout-border-width: 2px;
  --zb-flyout-border-color: color-mod(var(--zb-dark-grey-2) tint(20%));
  --zb-flyout-error-background: color-mod(var(--zb-red) tint(80%));
  --zb-flyout-error-border: 2px solid var(--zb-red);
  --zb-flyout-error-border-color: var(--zb-red);
  --zb-flyout-border-radius: 0;
  --zb-flyout-max-width: 400px;
  --zb-flyout-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  --zb-flyout-close-button-color: var(--zb-light-blue-2);
  --zb-flyout-close-button-offset: 15px;
  --zb-flyout-close-button-width: 14px;
  --zb-flyout-margin: 4px;
  --zb-flyout-arrow-size: 8px;

  /* ===================== */
  /* === FORM-ELEMENTS === */
  /* ===================== */

  --zb-checkbox-background: var(--zb-white);
  --zb-checkbox-border: 2px solid var(--zb-grey);
  --zb-checkbox-padding: 2px;
  --zb-checkbox-margin-right: 8px;
  --zb-checkbox-border-radius: 0;
  --zb-checkbox-tick-size: 24px;
  --zb-checkbox-font-size: var(--body-font-size);
  --zb-checkbox-tick-color: var(--zb-white);
  --zb-checkbox-checked-border: 2px solid var(--zb-royal-blue);
  --zb-checkbox-checked-background: var(--zb-royal-blue);
  --zb-checkbox-checked-tick-color: var(--zb-white);
  --zb-checkbox-focused-border: 2px solid var(--zb-focus);
  --zb-checkbox-focused-padding: 2px;
  --zb-checkbox-disabled-border: 2px solid color-mod(var(--zb-grey) tint(70%));
  --zb-checkbox-disabled-background: color-mod(var(--zb-royal-blue) tint(70%));
  --zb-checkbox-disabled-tick-color: var(--zb-white);
  --zb-checkbox-disabled-checked-border: 2px solid
    color-mod(var(--zb-royal-blue) tint(70%));
  --zb-checkbox-error-border: 2px solid var(--zb-red);
  --zb-checkbox-error-padding: 2px;
  --zb-checkbox-error-tick-color: var(--zb-red);
  --zb-checkbox-error-background: var(--zb-white);
  --zb-checkbox-labelled-padding: 6px;
  --zb-checkbox-labelled-focused-padding: 4px;
  --zb-checkbox-labelled-focused-border: 2px solid var(--zb-focus);
  --zb-checkbox-labelled-error-padding: 4px;
  --zb-checkbox-labelled-error-border: 2px solid var(--zb-red);
  --zb-checkbox-label-disabled-color: color-mod(var(--zb-black) tint(70%));

  --zb-radio-button-border: 2px solid var(--zb-grey);
  --zb-radio-button-padding: 6px;
  --zb-radio-font-size: var(--body-font-size);
  --zb-radio-button-circle-color: var(--zb-royal-blue);
  --zb-radio-button-circle-size: 16px;
  --zb-radio-button-selected-border: 3px solid var(--zb-royal-blue);
  --zb-radio-button-selected-padding: 5px;
  --zb-radio-button-focused-border: 3px solid var(--zb-focus);
  --zb-radio-button-focused-padding: 5px;
  --zb-radio-button-disabled-border: 2px solid
    color-mod(var(--zb-grey) tint(70%));
  --zb-radio-button-disabled-selected-border: 3px solid
    color-mod(var(--zb-royal-blue) tint(70%));
  --zb-radio-button-disabled-circle-color: color-mod(
    var(--zb-royal-blue) tint(70%)
  );
  --zb-radio-button-error-border: 2px solid var(--zb-red);
  --zb-radio-button-error-circle-color: var(--zb-red);
  --zb-radio-button-error-background: var(--zb-white);
  --zb-radio-button-labelled-padding: 6px;
  --zb-radio-button-labelled-focused-padding: 4px;
  --zb-radio-button-labelled-focused-border: 2px solid var(--zb-focus);
  --zb-radio-button-labelled-error-padding: 4px;
  --zb-radio-button-labelled-error-border: 2px solid var(--zb-red);
  --zb-radio-button-label-disabled-color: color-mod(var(--zb-black) tint(70%));

  --zb-file-input-error-background: color-mod(var(--zb-red) tint(90%));
  --zb-file-input-invalid-background-color: color-mod(var(--zb-gold) tint(90%));
  --zb-file-input-hover-background: color-mod(var(--zb-royal-blue) tint(90%));
  --zb-file-input-hover-background-color: color-mod(
    var(--zb-royal-blue) tint(90%)
  );

  /* ============= */
  /* === MODAL === */
  /* ============= */

  --zb-modal-border-radius: 0;
  --zb-modal-footer-padding: 0 30px 30px 30px;
  --zb-modal-is-sectioned-footer-padding: 30px;
  --zb-modal-header-padding: 0 54px 0 30px;
  --zb-modal-header-padding-vertical: 14px;
  --zb-modal-body-padding: 30px;
  --zb-modal-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  --zb-modal-separator: 1px solid color-mod(var(--zb-grey) tint(40%));
  --zb-modal-backdrop: rgba(0, 0, 0, 0.6);
  --zb-modal-background: var(--zb-white);
  --zb-modal-body-font-size: var(--body-font-size);
  --zb-modal-header-background: var(--zb-white);
  --zb-modal-header-color: var(--zb-royal-blue);
  --zb-modal-header-font-size: var(--display-2-font-size);
  --zb-modal-close-offset-right: 26px;
  --zb-modal-close-offset-top: 26px;
  --zb-modal-with-header-close-offset-right: 26px;
  --zb-modal-with-header-close-offset-top: calc(50% - 12px);
  --zb-modal-footer-background: var(--zb-white);
  --zb-modal-actions-margin: 10px;
  --zb-modal-footer-background: var(--zb-white);
  --zb-col-width: 10rem;

  /* =========== */
  /* === NAV === */
  /* =========== */

  --zb-nav-line-height: 1.5;
  --zb-nav-item-padding: 16px 20px;
  --zb-nav-item-color: var(--zb-white);
  --zb-nav-item-font-size: var(--body-font-size);
  --zb-nav-item-background: var(--zb-royal-blue);
  --zb-nav-item-hover-background: color-mod(var(--zb-royal-blue) tint(10%));
  --zb-nav-item-hover-color: var(--zb-white);
  --zb-nav-item-open-background: var(--zb-white);
  --zb-nav-item-open-color: var(--zb-royal-blue);
  --zb-nav-item-selected-background: var(--zb-royal-blue);
  --zb-nav-item-selected-color: var(--zb-white);
  --zb-nav-item-open-selected-background: var(--zb-white);
  --zb-nav-item-open-selected-color: var(--zb-royal-blue);
  --zb-nav-item-rightdivider-style: 1px solid
    color-mod(var(--zb-royal-blue) tint(20%));
  --zb-nav-menu-background: var(--zb-white);
  --zb-nav-menu-shadow: 0 5px 7px -1px rgba(128, 128, 128, 0.6);
  --zb-nav-menu-padding: 10px 0 12px 0;
  --zb-nav-menu-group-padding: 0;
  --zb-nav-menu-group-label-padding: 6px 24px;
  --zb-nav-menu-group-label-font-size: var(--display-3-font-size);
  --zb-nav-menu-group-label-color: var(--zb-black);
  --zb-nav-menu-item-hover-background: var(--zb-light-grey);
  --zb-nav-menu-item-selected-background: var(--zb-royal-blue);
  --zb-nav-menu-item-label-padding: 11px 24px;
  --zb-nav-menu-item-label-color: var(--zb-royal-blue);
  --zb-nav-menu-item-selected-label-color: var(--zb-white);
  --zb-nav-menu-item-label-text-decoration: none;
  --zb-nav-submenu-shadow: 0 1px 7px -1px rgba(128, 128, 128, 0.6);

  /* ==================== */
  /* === NOTIFICATION === */
  /* ==================== */

  --zb-notification-line-height: var(--body-line-height);
  --zb-notification-color: var(--zb-dark-grey-2);
  --zb-notification-background: var(--zb-white);
  --zb-notification-title-font-weight: normal;
  --zb-notification-title-color: var(--zb-black);

  --zb-notification-title-font-size: var(--display-3-font-size);
  --zb-notification-title-font-size-large: var(--display-2-font-size);

  --zb-notification-small-padding: 20px;
  --zb-notification-medium-padding: 20px;
  --zb-notification-large-padding: 20px;
  --zb-notification-info-border-width: 2px;
  --zb-notification-error-border-width: 2px;
  --zb-notification-warning-border-width: 2px;
  --zb-notification-success-border-width: 2px;

  --zb-notification-error-border-color: var(--zb-red);
  --zb-notification-warning-border-color: var(--zb-gold);
  --zb-notification-success-border-color: var(--zb-green);
  --zb-notification-info-border-color: var(--zb-dark-grey-2);
  --zb-notification-error-background: color-mod(var(--zb-red) tint(90%));
  --zb-notification-warning-background: color-mod(var(--zb-gold) tint(90%));
  --zb-notification-success-background: color-mod(var(--zb-green) tint(90%));
  --zb-notification-info-background: var(--zb-light-grey);
  --zb-notification-arrow-size: 10px;

  --zb-notification-has-status-background-color: var(--zb-black);

  --zb-notification-small-icon-padding: 20px;
  --zb-notification-medium-icon-padding: 30px;
  --zb-notification-large-icon-padding: 30px;

  --zb-notification-small-icon-padding-right: 15px;
  --zb-notification-medium-icon-padding-right: 15px;
  --zb-notification-large-icon-padding-right: 20px;

  --zb-notification-small-icon-size: 20px;
  --zb-notification-medium-icon-size: 32px;
  --zb-notification-large-icon-size: 40px;

  --zb-notification-small-min-height: 56px;
  --zb-notification-medium-min-height: 76px;
  --zb-notification-large-min-height: 92px;

  --zb-notification-width-inside-card: 670px;

  /* ============= */
  /* === PAGER === */
  /* ============= */

  --zb-pager-item-padding: 0 2px;
  --zb-pager-font-size: var(--body-font-size);
  --zb-pager-navigation-active-color: black;
  --zb-pager-navigation-active-font-weight: normal;
  --zb-pager-navigation-active-text-decoration: none;
  --zb-pager-navigation-disabled-color: color-mod(var(--zb-grey) tint(40%));
  --zb-pager-icon-size: 16px;

  /* ========================== */
  /* === PROGRESS-INDICATOR === */
  /* ========================== */

  --zb-step-size: 40px;
  --zb-step-size-large: 52px;
  --zb-step-line-height: 4px;
  --zb-progress-indicator-primary-color: var(--zb-royal-blue);
  --zb-step-border-radius: 50%;
  --zb-step-is-selected-bottom-shadow: none;
  --zb-step-selected-index-font-size: var(--display-3-font-size);
  --zb-step-index-color: var(--zb-royal-blue);
  --zb-step-description-color: var(--zb-dark-grey-2);
  --zb-progress-indicator-step-font-size: var(--body-small-font-size);
  --zb-step-future-track-background: var(--zb-grey);
  --zb-progress-indicator-icon-size: 16px;

  /* ================== */
  /* === SIDE-PANEL === */
  /* ================== */

  --zb-sidepanel-padding-left: 0;
  --zb-sidepanel-width: 600px;
  --zb-sidepanel-body-max-height: 330px;
  --zb-sidepanel-position-top: 50%;
  --zb-sidepanel-position-bottom: auto;
  --zb-sidepanel-transform: translateY(-50%);
  --zb-sidepanel-border-radius: 0;
  --zb-sidepanel-header-padding: 7px 30px;
  --zb-sidepanel-title-font-size: var(--display-5-font-size);
  --zb-sidepanel-title-line-height: var(--display-5-line-height);
  --zb-sidepanel-title-color: var(--zb-royal-blue);
  --zb-sidepanel-footer-padding: 30px;
  --zb-sidepanel-body-content-padding: 30px;
  --zb-sidepanel-separator: 1px solid color-mod(var(--zb-grey) tint(40%));
  --zb-sidepanel-background: transparent;
  --zb-sidepanel-body-font-size: var(--body-font-size);
  --zb-sidepanel-header-background: var(--zb-white);
  --zb-sidepanel-header-faded-background: var(--zb-light-grey);
  --zb-sidepanel-shadow-color: rgba(0, 0, 0, 0.1);
  --zb-sidepanel-list-padding-left: 18px;
  --zb-sidepanel-list-item-margin-bottom: 1em;
  --zb-sidepanel-wrapper-position-bottom: 0;
  --zb-sidepanel-wrapper-position-top: 0;
  --zb-sidepanel-wrapper-transform: translateY(0);
  --zb-sidepanel-backdrop: rgba(0, 0, 0, 0.6);

  /* ============== */
  /* === SLIDER === */
  /* ============== */

  --zb-slider-disabled-opacity: 0.4;
  --zb-slider-button-border: 1px solid var(--zb-sea-blue);
  --zb-slider-button-border-hover: 2px solid var(--zb-sea-blue);
  --zb-slider-button-radius: 16px;
  --zb-slider-thumb-background: svg-inline(--zb-slider-thumb);
  --zb-slider-thumb-background-hover: svg-inline(--zb-slider-thumb-hover);
  --zb-slider-track-background: var(--zb-grey);
  --zb-slider-focus: var(--zb-focus);
  --zb-slider-track-progress-background: var(--zb-royal-blue);
  --zb-slider-tooltip-padding: 4px 8px;
  --zb-slider-tooltip-font-size: var(--body-small-font-size);
  --zb-slider-tooltip-background: var(--zb-light-grey-2);
  --zb-slider-tooltip-border-width: 2px;
  --zb-slider-tooltip-border-color: color-mod(var(--zb-dark-grey-2) tint(20%));
  --zb-slider-tooltip-arrow-size: 7px;
  --zb-slider-tooltip-arrow-spacing: 4px;

  /* ==================== */
  /* === SPLIT-BUTTON === */
  /* ==================== */

  --zb-split-button-font-size: var(--body-font-size);
  --zb-split-button-border-radius: 0;
  --zb-split-button-focus-outline-color: var(--zb-focus);

  --zb-split-button-primary-color: var(--zb-white);
  --zb-split-button-primary-border: none;
  --zb-split-button-primary-background: var(--zb-sea-blue);
  --zb-split-button-primary-hover-background: var(--zb-light-blue);
  --zb-split-button-primary-focus-background: var(--zb-light-blue);
  --zb-split-button-primary-disabled-background: var(--zb-grey);
  --zb-split-button-primary-disabled-color: var(--zb-dark-grey);
  --zb-split-button-primary-disabled-border: none;
  --zb-split-button-primary-divider-color: var(--zb-white);
  --zb-split-button-primary-label-line-height: 1;
  --zb-split-button-primary-label-padding: 14px 32px;
  --zb-split-button-primary-icon-width: 44px;
  --zb-split-button-primary-border-radius: 22px;
  --zb-split-button-primary-icon-padding: 12px;

  --zb-split-button-secondary-color: var(--zb-sea-blue);
  --zb-split-button-secondary-background: transparent;
  --zb-split-button-secondary-divider-color: var(--zb-sea-blue);
  --zb-split-button-secondary-border: 1px solid var(--zb-sea-blue);
  --zb-split-button-secondary-hover-color: var(--zb-sea-blue);
  --zb-split-button-secondary-hover-background: transparent;
  --zb-split-button-secondary-hover-border: 2px solid var(--zb-sea-blue);
  --zb-split-button-secondary-focus-color: var(--zb-sea-blue);
  --zb-split-button-secondary-focus-background: transparent;
  --zb-split-button-secondary-focus-border: 2px solid var(--zb-sea-blue);
  --zb-split-button-secondary-disabled-background: var(--zb-grey);
  --zb-split-button-secondary-disabled-color: var(--zb-dark-grey);
  --zb-split-button-secondary-disabled-border: 1px solid var(--zb-grey);
  --zb-split-button-secondary-disabled-divider-color: var(--zb-white);
  --zb-split-button-secondary-label-line-height: 1;
  --zb-split-button-secondary-label-padding: 13px 32px;
  --zb-split-button-secondary-label-hover-padding: 12px 32px 12px 31px;
  --zb-split-button-secondary-border-radius: 22px;
  --zb-split-button-secondary-icon-width: 44px;
  --zb-split-button-secondary-icon-padding: 11px 12px;
  --zb-split-button-secondary-icon-hover-padding: 10px 12px;

  /* =============== */
  /* === TAB-BAR === */
  /* =============== */

  --zb-tab-bar-separator-color: var(--zb-grey);
  --zb-tab-bar-padding: 7px 16px;
  --zb-tab-bar-margin: 0 2px;
  --zb-tab-bar-first-child-left-margin: 0;
  --zb-tab-bar-border-radius: 0;
  --zb-tab-bar-border-width: 1px;
  --zb-tab-bar-arrow-width: 0;
  --zb-tab-bar-arrow-height: 0;
  --zb-tab-bar-text-color: var(--zb-light-blue-2);
  --zb-tab-bar-text-color-selected: var(--zb-royal-blue);
  --zb-tab-bar-text-color-disabled: var(--zb-dark-grey);
  --zb-tab-bar-background-disabled: var(--zb-grey);
  --zb-tab-bar-bottom-separator-color: var(--zb-black);
  --zb-tab-bar-font-size: var(--body-font-size);
  --zb-tab-bar-item-background: var(--zb-light-grey);
  --zb-tab-bar-item-background-selected: var(--zb-white);
  --zb-tab-bar-item-border: 1px solid color-mod(var(--zb-grey) tint(50%));
  --zb-tab-bar-item-border-bottom: 1px solid transparent;
  --zb-tab-bar-with-separator-item-border: 1px solid
    color-mod(var(--zb-grey) tint(50%));
  --zb-tab-bar-with-separator-item-selected-border: 1px solid var(--zb-grey);
  --zb-tab-bar-with-separator-item-border-bottom: 1px solid var(--zb-grey);
  --zb-tab-bar-with-separator-item-selected-border-bottom: 1px solid transparent;
  --zb-tab-bar-border-selected: 1px solid transparent;
  --zb-tab-bar-border-bottom-selected: 1px solid transparent;
  --zb-tab-bar-focus: var(--zb-focus);
  --zb-tab-bar-box-shadow-selected: 2px 0 2px -2px rgba(0, 0, 0, 0.14),
    -2px 0 2px -2px rgba(0, 0, 0, 0.14);
  --zb-tab-bar-box-shadow-focus: 0 0 0 1px white, 0 0 0 3px var(--zb-focus);
  --zb-tab-bar-item-full-size-margin: -7px -16px;

  /* ============== */
  /* === TOGGLE === */
  /* ============== */

  --zb-toggle-margin-top: 4px;
  --zb-toggle-star-color: var(--zb-grey);
  --zb-toggle-star-hover-color: var(--zb-dark-grey-2);
  --zb-toggle-star-on-color: var(--zb-focus);
  --zb-toggle-star-on-hover-color: color-mod(var(--zb-focus) shade(20%));
  --zb-toggle-star-focus-outline: 2px solid var(--zb-focus);

  --zb-toggle-switch-line-height: 1.5;
  --zb-toggle-switch-border-radius: 16px;
  --zb-toggle-switch-focus-outline-color: var(--zb-focus);
  --zb-toggle-switch-on-border: 1px solid var(--zb-grey);
  --zb-toggle-switch-border: 1px solid var(--zb-grey);
  --zb-toggle-switch-color: var(--zb-dark-grey-2);
  --zb-toggle-switch-hover-color: var(--zb-light-grey-2);
  --zb-toggle-switch-on-color: var(--zb-dark-grey-2);
  --zb-toggle-switch-on-button-color: var(--zb-light-grey-2);
  --zb-toggle-switch-on-hover-color: var(--zb-light-grey-2);
  --zb-toggle-switch-padding: 3px 1px;
  --zb-toggle-switch-on-padding: 3px 1px;
  --zb-toggle-switch-size: 24px;
  --zb-toggle-switch-button-left: 0;
  --zb-toggle-switch-on-button-left: 0;
  --zb-toggle-switch-background-color: var(--zb-white);

  --zb-toggle-switch-label-invert-display: inline;
  --zb-toggle-switch-button-position: inherit;
  --zb-toggle-switch-font-size: var(--body-small-font-size);
  --zb-toggle-switch-button-border-radius: 12px;
  --zb-toggle-switch-button-border: 1px solid var(--zb-grey);
  --zb-toggle-switch-label-padding: 3px 2px 0;
  --zb-toggle-switch-button-width: 40px;
  --zb-toggle-switch-button-background: var(--zb-light-grey-2);
}

@media (--mobile-viewport) {
  :root {
    /* ================= */
    /* === ACCORDION === */
    /* ================= */

    --zb-accordion-header-padding: 0 56px 0 20px;
    --zb-accordion-header-is-last-padding: 0 40px 0 20px;
    --zb-accordion-header-font-size: var(--display-5-font-size);
    --zb-accordion-content-font-size: var(--body-small-font-size);
    --zb-accordion-content-padding: 20px;
    --zb-accordion-nested-header-padding: 0 20px 0 40px;
    --zb-accordion-nested-is-last-header-padding: 0 20px 0 40px;
    --zb-accordion-nested-content-padding: 20px;
    --zb-accordion-nested-header-font-size: var(--body-small-font-size);
    --zb-accordion-with-padding-nested-content-padding: 20px;
    --zb-accordion-header-icon-margin-right: 20px;
    --zb-accordion-nested-header-height: 40px;
    --zb-accordion-with-padding-nested-header-padding: 0 30px 0 40px;
    --zb-accordion-with-padding-nested-is-last-header-padding: 0 30px 0 40px;

    /* ==================== */
    /* === BUTTON-GROUP === */
    /* ==================== */

    --zb-button-group-item-padding: 8px;
    --zb-button-group-item-font-size: var(--body-small-font-size);

    /* ================ */
    /* === CORE-CSS === */
    /* ================ */

    --zb-font-size-main: var(--body-small-font-size);

    --zb-input-height: 40px;
    --zb-input-font-size: var(--body-small-font-size);

    --zb-button-primary-padding-top: 12px;
    --zb-button-font-size: var(--body-small-font-size);
    --zb-button-primary-padding: 12px 32px;
    --zb-button-primary-line-height: var(--body-small-line-height);
    --zb-button-secondary-padding: 11px 32px;
    --zb-button-secondary-padding-rollover: 10px 31px;
    --zb-button-secondary-line-height: var(--body-small-line-height);
    --zb-button-primary-padding-top: 12px;

    --zb-breadcrumb-font-size: var(--body-small-font-size);
    --zb-breadcrumbs-arrow-size: 14px;

    --zb-tertiary-cta-font-size: var(--body-small-font-size);
    --zb-tertiary-cta-icon-size: 12px;

    --zb-card-padding: 20px;
    --zb-card-title-font-size: var(--display-5-font-size);
    --zb-card-body-font-size: var(--body-small-font-size);

    --zb-feature-button-title-font-size: 16px;
    --zb-feature-button-font-size: var(--body-small-font-size);

    --zb-masthead-height: 52px;
    --zb-masthead-background: var(--zb-royal-blue);
    --zb-masthead-logo: svg-inline(--zb-asset-logo-small);
    --zb-masthead-logo-asset: url(./assets/svg/RBS_logo_small.svg);
    --zb-masthead-logo-height: 28px;
    --zb-masthead-logo-width: 101px;

    --zb-table-font-size: var(--body-small-font-size);
    --zb-table-column-header-font-size: var(--body-small-font-size);
    --zb-table-checkbox-size: 16px;

    --zb-table-card-header-padding: 14px 20px;
    --zb-table-cell-padding: 20px;
    --zb-table-first-cell-padding: 20px;
    --zb-table-cell-header-color: var(--zb-heather);
    --zb-table-row-hover-cell-background: var(--zb-light-grey);

    --zb-table-toolbar-select-font-size: var(--body-small-font-size);
    --zb-table-toolbar-select-icon-size: 10px;
    --zb-table-toolbar-select-height: 28px;

    --zb-nested-table-cell-padding: 4px;
    --zb-nested-table-cell-padding-left: 16px;
    --zb-nested-table-head-padding: 8px;

    --zb-flushed-table-cell-padding: 4px;
    --zb-flushed-table-cell-padding-left: 20px;
    --zb-flushed-table-head-padding: 12px 8px;

    --zb-complex-table-cell-padding: 12px 20px;
    --zb-complex-table-cell-height: auto;
    --zb-complex-table-column-header-padding: 8px;
    --zb-complex-table-column-header-height: auto;
    --zb-complex-table-toolbar-padding: 10px 12px;
    --zb-complex-table-toolbar-height: auto;

    --zb-left-hand-nav-font-size: var(--body-small-font-size);
    --zb-left-hand-nav-header-line-height: 16px;
    --zb-left-hand-nav-header-padding: 17px 20px;
    --zb-left-hand-nav-item-line-height: 16px;
    --zb-left-hand-nav-item-padding: 17px 30px;

    /* =================== */
    /* === DATE-PICKER === */
    /* =================== */

    --zb-calendar-width: 280px;
    --zb-calendar-item-size: 40px;
    --zb-datepicker-button-size: 30px;
    --zb-calendar-font-size: var(--body-small-font-size);
    --zb-calendar-row-padding-left: 6px;
    --zb-calendar-row-padding-right: 6px;
    --zb-datepicker-title-padding: 10px 0;
    --zb-calendar-margin-top: 10px;
    --zb-calendar-margin-bottom: 10px;
    --zb-month-calendar-months-container-padding: 5px 0;
    --zb-month-calendar-month-margin: 5px 26px;
    --zb-datepicker-days-font-size: var(--body-small-font-size);

    --zb-date-stepper-button-top: 14px;
    --zb-date-stepper-button-left: 10px;
    --zb-date-stepper-button-right: 10px;
    --zb-datepicker-title-padding: 10px 0;

    /* ===================== */
    /* === DROPDOWN-LIST === */
    /* ===================== */

    --zb-lookup-input-font-size: var(--body-small-font-size);
    --zb-dropdown-list-item-font-size: var(--body-small-font-size);

    --zb-lookup-min-height: 40px;
    --zb-lookup-input-min-height: 40px; /* --zb-input-height - (2 * --zb-input-focus-border) */
    --zb-lookup-line-height: 40px; /* --zb-input-height - (2 * --zb-input-focus-border) */

    --zb-dropdown-header-padding: 10px 12px;
    --zb-dropdown-header-font-size: var(--body-small-font-size);
    --zb-dropdown-footer-padding: 10px 12px;
    --zb-dropdown-footer-font-size: var(--body-small-font-size);

    --zb-dropdown-list-item-padding: 10px 12px;
    --zb-dropdown-list-item-hover-padding: 10px 12px;
    --zb-dropdown-list-group-label-padding: 10px 12px;

    --zb-select-dropdown-list-item-selected-padding: 10px 30px 10px 12px;

    --zb-multiselect-dropdown-list-item-icon-size: 18px;
    --zb-dropdown-trigger-icon-size: 13px;

    /* ============== */
    /* === FLYOUT === */
    /* ============== */
    --zb-flyout-font-size: var(--body-small-font-size);

    /* ================= */
    /* === Footer === */
    /* ================= */

    --zb-footer-padding: 22px 4px;
    --zb-footer-nav-item-margin-bottom: 20px;
    --zb-footer-nav-item-last-margin-bottom: 20px;
    --zb-footer-text-margin-top: 10px;

    /* ===================== */
    /* === FORM-ELEMENTS === */
    /* ===================== */

    --zb-checkbox-padding: 0;
    --zb-checkbox-tick-size: 16px;
    --zb-checkbox-font-size: var(--body-small-font-size);
    --zb-checkbox-focused-padding: 0;
    --zb-checkbox-error-padding: 0;

    --zb-radio-button-padding: 3px;
    --zb-radio-button-circle-size: 10px;
    --zb-radio-font-size: var(--body-small-font-size);
    --zb-radio-button-selected-border: 2px solid var(--zb-royal-blue);
    --zb-radio-button-selected-padding: 3px;
    --zb-radio-button-focused-border: 3px solid var(--zb-focus);
    --zb-radio-button-focused-padding: 3px;
    --zb-radio-button-disabled-selected-border: 2px solid
      color-mod(var(--zb-royal-blue) tint(30%));

    /* ============= */
    /* === MODAL === */
    /* ============= */

    --zb-modal-footer-padding: 0 20px 20px;
    --zb-modal-is-sectioned-footer-padding: 20px;
    --zb-modal-header-padding: 0 40px 0 20px;
    --zb-modal-header-padding-vertical: 10px;
    --zb-modal-body-padding: 20px;
    --zb-modal-close-offset-right: 16px;
    --zb-modal-close-offset-top: 16px;
    --zb-modal-with-header-close-offset-right: 16px;
    --zb-modal-actions-margin: 5px;
    --zb-modal-header-font-size: var(--display-5-font-size);
    --zb-modal-body-font-size: var(--body-small-font-size);

    /* ============= */
    /* === NAV === */
    /* ============= */
    --zb-nav-item-font-size: var(--body-small-font-size);

    /* ==================== */
    /* === NOTIFICATION === */
    /* ==================== */

    --zb-notification-title-font-size: var(--body-small-font-size);
    --zb-notification-title-font-size-large: var(--body-small-font-size);
    --zb-notification-title-font-weight: bold;

    --zb-notification-small-icon-padding: 20px;
    --zb-notification-medium-icon-padding: 20px;
    --zb-notification-large-icon-padding: 20px;

    --zb-notification-small-icon-padding-right: 10px;
    --zb-notification-medium-icon-padding-right: 10px;
    --zb-notification-large-icon-padding-right: 10px;

    --zb-notification-small-icon-size: 20px;
    --zb-notification-medium-icon-size: 20px;
    --zb-notification-large-icon-size: 20px;

    --zb-notification-small-min-height: 56px;
    --zb-notification-medium-min-height: 56px;
    --zb-notification-large-min-height: 56px;

    /* ============= */
    /* === PAGER === */
    /* ============= */

    --zb-pager-font-size: var(--body-small-font-size);
    --zb-pager-icon-size: 13px;

    /* ========================== */
    /* === PROGRESS-INDICATOR === */
    /* ========================== */
    --zb-step-size: 20px;
    --zb-step-size-large: 32px;
    --zb-step-line-height: 4px;
    --zb-progress-indicator-step-font-size: var(--body-small-font-size);
    --zb-step-selected-index-font-size: var(--body-small-font-size);
    --zb-progress-indicator-icon-size: 12px;

    /* ================== */
    /* === SIDE-PANEL === */
    /* ================== */

    --zb-sidepanel-width: calc(100% - 40px);
    --zb-sidepanel-body-max-height: 100%;
    --zb-sidepanel-position-top: 0;
    --zb-sidepanel-position-bottom: 0;
    --zb-sidepanel-transform: translateY(0);
    --zb-sidepanel-header-padding: 7px 20px;
    --zb-sidepanel-title-font-size: var(--display-6-font-size);
    --zb-sidepanel-title-line-height: var(--display-6-line-height);
    --zb-sidepanel-title-height: 34px;
    --zb-sidepanel-footer-padding: 20px;
    --zb-sidepanel-body-content-padding: 20px;
    --zb-sidepanel-body-font-size: var(--body-small-font-size);

    /* ==================== */
    /* === SPLIT-BUTTON === */
    /* ==================== */

    --zb-split-button-font-size: var(--body-small-font-size);
    --zb-split-button-primary-label-padding: 12px 32px;
    --zb-split-button-primary-icon-width: 40px;
    --zb-split-button-primary-icon-padding: 12px;
    --zb-split-button-primary-label-line-height: 1.25;

    --zb-split-button-secondary-label-padding: 11px 32px;
    --zb-split-button-secondary-label-hover-padding: 10px 32px 10px 31px;
    --zb-split-button-secondary-icon-width: 40px;
    --zb-split-button-secondary-icon-padding: 11px 12px;
    --zb-split-button-secondary-icon-hover-padding: 10px 12px;
    --zb-split-button-secondary-label-line-height: 1.25;

    /* =============== */
    /* === TAB-BAR === */
    /* =============== */

    --zb-tab-bar-padding: 4px 8px;
    --zb-tab-bar-font-size: var(--body-small-font-size);

    /* ============== */
    /* === TOGGLE === */
    /* ============== */

    --zb-toggle-switch-size: 21px;
    --zb-toggle-switch-label-padding: 2px 2px 0;
  }
}

@media (--tablet-viewport) {
  :root {
    /* ================= */
    /* === Footer === */
    /* ================= */
    --zb-footer-padding: 14px 6px;
    --zb-footer-nav-item-margin-bottom: 20px;
    --zb-footer-nav-item-last-margin-bottom: 30px;
    --zb-footer-text-margin-top: 0;
  }
}
