@import "@zambezi/sdk/src/nav/css/base.css";
@import "@zambezi/sdk/src/nav/css/theme.css";

:--theme {
  & .zb-nav-item {
    font-size: var(--zb-nav-item-font-size);
  }

  & .zb-nav-menu-item-open-icon {
    flex-shrink: 0;
  }

  & .zb-nav-vertical {
    & .zb-nav-item-label,
    & .zb-nav-item {
      display: block;
    }

    & .zb-nav-item-label {
      line-height: inherit;

      & > .zb-nav-item-right-icon {
        margin-top: 3px;
      }
    }

    & span.zb-nav-item-label {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      color: var(--zb-nav-menu-item-label-color);
      text-decoration: var(--zb-nav-menu-item-label-text-decoration, initial);
    }

    & .zb-nav-item-right-icon {
      display: inline-block;
    }

    & .zb-nav-menu-group-label {
      display: block;
    }

    & .zb-nav-menu-item-label[aria-expanded="true"] {
      & .zb-nav-menu-item-open-icon {
        transform: scaleY(-1); /* flip the icon upside down when expanded */
      }
    }

    & .zb-nav-menu-group-label,
    & .zb-nav-item-is-open .zb-nav-item-label,
    & .zb-nav-menu-item-label[aria-expanded="true"] {
      font-weight: bold;
    }

    & .zb-nav-menu-item-label[aria-expanded="true"] {
      &:hover {
        background: var(--zb-nav-menu-item-hover-background, transparent);
      }
    }

    & .zb-nav-menu-group-label {
      position: relative;
      font-size: var(--zb-nav-item-font-size);
    }

    & .zb-nav-submenu {
      & .zb-nav-menu-item-label {
        &:hover {
          background: var(--zb-nav-menu-item-hover-background, transparent);
        }
      }
    }

    & .zb-nav-menu-item-is-selected,
    & .zb-nav-menu-item-is-selected:hover {
      & .zb-nav-menu-item-label {
        background: var(--zb-nav-menu-item-selected-background);
        color: var(--zb-nav-menu-item-selected-label-color);
      }
    }

    & .zb-nav-item.zb-nav-item-is-selected .zb-nav-item-label,
    &
      .zb-nav-item.zb-nav-item-is-open.zb-nav-item-is-selected
      .zb-nav-item-label,
    & .zb-nav-item-is-open .zb-nav-item-label {
      & .zb-icon {
        color: inherit;
      }
    }
  }
}
