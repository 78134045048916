@import '@zambezi/sdk/src/notification/css/base.css';
@import '@zambezi/sdk/src/notification/css/theme.css';

:--theme {
  & .zb-notification-small {

    & .zb-notification-inner {
      padding: var(--zb-notification-small-padding);
      padding-left: calc(var(--zb-notification-small-icon-padding, var(--zb-notification-small-icon-padding)) + var(--zb-notification-small-icon-padding-right) + var(--zb-notification-small-icon-size));
    }

    & .zb-notification-icon {
      left: var(--zb-notification-small-icon-padding);
      top: var(--zb-notification-small-padding);
    }
  }

  & .zb-notification-medium {

    & .zb-notification-inner {
      padding: var(--zb-notification-medium-padding);
      padding-left: calc(var(--zb-notification-medium-icon-padding, var(--zb-notification-medium-icon-padding)) + var(--zb-notification-medium-icon-padding-right) + var(--zb-notification-medium-icon-size));
    }

    & .zb-notification-icon {
      left: var(--zb-notification-medium-icon-padding);
      top: var(--zb-notification-medium-padding);
    }
  }

  & .zb-notification-large {
    color: var(--zb-notification-color);

    & .zb-notification-inner {
      padding: var(--zb-notification-large-padding);
      padding-left: calc(var(--zb-notification-large-icon-padding, var(--zb-notification-large-icon-padding)) + var(--zb-notification-large-icon-padding-right) + var(--zb-notification-large-icon-size));
      padding-right: var(--zb-notification-large-icon-padding);
    }

    & .zb-notification-icon {
      left: var(--zb-notification-large-icon-padding);
      top: var(--zb-notification-large-padding);
    }

    & .zb-notification-title {
      font-size: var(--zb-notification-title-font-size-large);
    }
  }
}