@import '@zambezi/sdk/src/dropdown-list/css/base.css';
@import '@zambezi/sdk/src/dropdown-list/css/theme.css';

:--theme {
	& .zb-dropdown-header {
		font-size: var(--zb-dropdown-header-font-size);
	}

	& .zb-dropdown-footer {
		font-size: var(--zb-dropdown-footer-font-size);
	}

	& .zb-select .zb-select-input-wrapper,
	& .zb-combobox .zb-lookup-input-wrapper {
		& .zb-icon {
			padding: var(--zb-lookup-input-icon-padding);
		}
	}

	& .zb-multiselect-dropdown-list-item-icon,
	& .zb-multiselect-dropdown-list-group-label-icon {
		padding: 0;
		margin-right: var(--zb-multiselect-dropdown-list-item-icon-margin-right);
		width: var(--zb-multiselect-dropdown-list-item-icon-size);
		height: var(--zb-multiselect-dropdown-list-item-icon-size);
	}

	& .zb-multiselect-dropdown-list-item-is-selected {
		& .zb-multiselect-dropdown-list-item-icon,
		& .zb-multiselect-dropdown-list-group-label-icon {
			background: var(
				--zb-multiselect-dropdown-list-item-selected-icon-background
			);
			border: var(--zb-multiselect-dropdown-list-item-selected-icon-border);
		}
		& .zb-icon-core-dropdown-item-selected {
			color: var(--zb-multiselect-dropdown-list-item-selected-icon-color);
		}
	}

	& .ddl-trigger {
		& .zb-icon {
			width: var(--zb-dropdown-trigger-icon-size);
			height: var(--zb-dropdown-trigger-icon-size);
		}
	}

	& .zb-lookup-input-wrapper-is-disabled,
	& .zb-select-is-mobile.zb-select-mobile-is-disabled {
		& .zb-icon {
			color: var(--zb-lookup-icon-disabled-color);
		}
	}
}
