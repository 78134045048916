@import '@zambezi/sdk/src/core-css/base/core.css';

:--theme {
  background-color: var(--zb-theme-background-color, #fff);

  & .zb-heading1 {
    font-family: var(--zb-heading1-font-family);
    font-size: var(--zb-heading1-font-size);
    font-weight: var(--zb-heading1-font-weight);
    line-height: var(--zb-heading1-line-height);
    color: var(--zb-heading1-color, inherit);
  }

  & .zb-heading2 {
    font-family: var(--zb-heading2-font-family);
    font-size: var(--zb-heading2-font-size);
    font-weight: var(--zb-heading2-font-weight);
    line-height: var(--zb-heading2-line-height);
    color: var(--zb-heading2-color, inherit);
  }

  & .zb-heading3 {
    font-family: var(--zb-heading3-font-family);
    font-size: var(--zb-heading3-font-size);
    font-weight: var(--zb-heading3-font-weight);
    line-height: var(--zb-heading3-line-height);
    color: var(--zb-heading3-color, inherit);
  }

  & .zb-heading4 {
    font-family: var(--zb-heading4-font-family);
    font-size: var(--zb-heading4-font-size);
    font-weight: var(--zb-heading4-font-weight);
    line-height: var(--zb-heading4-line-height);
    color: var(--zb-heading4-color, inherit);
  }

  & .zb-heading5 {
    font-family: var(--zb-heading5-font-family);
    font-size: var(--zb-heading5-font-size);
    font-weight: var(--zb-heading5-font-weight);
    line-height: var(--zb-heading5-line-height);
    color: var(--zb-heading5-color, inherit);
  }

  & .zb-heading6 {
    font-family: var(--zb-heading6-font-family);
    font-size: var(--zb-heading6-font-size);
    font-weight: var(--zb-heading6-font-weight);
    line-height: var(--zb-heading6-line-height);
    color: var(--zb-heading6-color, inherit);
  }

  & .zb-body2-small {
    font-size: var(--zb-body2-small-font-size);
    line-height: var(--zb-body2-small-line-height);
    font-family: var(--zb-body2-small-font-family);
  }

  & .zb-body-small {
    font-size: var(--zb-body-small-font-size);
    line-height: var(--zb-body-small-line-height);
    font-family: var(--zb-body-small-font-family);
  }

  & .zb-body-small-bold {
    font-size: var(--zb-body-small-bold-font-size);
    line-height: var(--zb-body-small-bold-line-height);
    font-family: var(--zb-body-small-bold-font-family);
    font-weight: var(--zb-body-small-bold-font-weight);
  }

  & .zb-input,
  & .zb-textarea,
  & .zb-input-wrapper > input,
  & .zb-input-wrapper > input:focus,
  & .zb-input-wrapper > input:disabled,
  & input,
  & textarea {
    font-size: var(--zb-input-font-size);
  }

  & .zb-dropdown-container {
    min-width: var(--zb-dropdown-container-min-width);
    min-height: calc(var(--zb-dropdown-list-item-min-height) * 2);
  }

  & .zb-has-scrollbar {
    &::-webkit-scrollbar {
      background-color: var(--zb-scrollbar-background-color);
      width: 24px;
      height: 24px;
    }

    &::-webkit-scrollbar-track:vertical,
    &::-webkit-scrollbar-thumb:vertical {
      border-top-left-radius: var(--zb-scrollbar-thumb-radius, 10px);
      border-bottom-left-radius: var(--zb-scrollbar-thumb-radius, 10px);
      border-top-right-radius: calc(
        var(--zb-scrollbar-thumb-radius, 10px) - 0.5px
      ); /* border left is 1px bigger than right so we need to accomodate the radius*/
      border-bottom-right-radius: calc(
        var(--zb-scrollbar-thumb-radius, 10px) - 0.5px
      );
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    &::-webkit-scrollbar-track:horizontal,
    &::-webkit-scrollbar-thumb:horizontal {
      border-top-left-radius: var(--zb-scrollbar-thumb-radius, 10px);
      border-top-right-radius: var(--zb-scrollbar-thumb-radius, 10px);
      border-bottom-left-radius: calc(
        var(--zb-scrollbar-thumb-radius, 10px) - 0.5px
      ); /* border top is 1px bigger than bottom so we need to accomodate the radius*/
      border-bottom-right-radius: calc(
        var(--zb-scrollbar-thumb-radius, 10px) - 0.5px
      );
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }

  & a {
    &:active {
      color: var(--zb-link-active-color, var(--zb-link-hover-color));
      text-decoration: var(--zb-link-hover-text-decoration);
    }

    &:focus {
      outline: 1px solid var(--zb-link-outline-color, var(--zb-color-focus, transparent));
      outline-offset: var(--zb-link-outline-offset, 0);
      text-decoration: var(--zb-link-focus-text-decoration, none);
    }

    &.zb-tertiary-cta {
      font-size: var(--zb-tertiary-cta-font-size, var(--body-font-size));
      color: var(--zb-tertiary-cta-color, var(--zb-feature-button-color));
      text-decoration: var(--zb-tertiary-cta-text-decoration, underline);

      &:hover {
        color: var(
          --zb-tertiary-cta-hover-color,
          var(--zb-feature-button-color)
        );
        text-decoration: var(--zb-tertiary-cta-hover-text-decoration, none);
      }

      &:active {
        color: var(
          --zb-tertiary-cta-active-color,
          var(--zb-tertiary-cta-hover-color)
        );
      }

      &:focus {
        text-decoration: var(--zb-tertiary-cta-focus-text-decoration, none);
      }

      & .zb-icon {
        color: inherit;
        width: var(--zb-tertiary-cta-icon-size, 12px);
        height: var(--zb-tertiary-cta-icon-size, 12px);
        line-height: var(--zb-tertiary-cta-icon-size, 12px);
        border-left: 8px solid transparent;
        vertical-align: middle;
      }
    }

    &.zb-inverted-cta,
    &.zb-tertiary-cta.zb-inverted-cta {
      color: var(--zb-inverted-cta-color, var(--zb-white));

      &:hover {
        color: var(--zb-inverted-cta-hover-color, var(--zb-white));
      }

      &:active {
        color: var(--zb-inverted-cta-active-color, var(--zb-white));
      }
    }
  }
}
